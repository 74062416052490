<script setup>

import { ref, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';

import { figurePostPutOut } from '@/utils/http';

import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { isValidElements, hasValidLimit } from '@/views/buyers/settings/packs/helpers/packUtils';

import { icons } from '@/utils/icons';
import ItemFormLayout from '@/views/_shared/Layouts/ItemFormLayout.vue';
import { useDraggable } from 'vue-draggable-plus'

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
});

const own = ref({
    ...props.item,
    inputCount: 0,
});

onMounted(() => {
    if (!own.value.elements) {
        own.value.elements = [];
    }

    if (own.value.elements.length > 0) {
        sZeroForm.value = own.value.elements.filter(x => x.scheme === 0);
        sOneForm.value = own.value.elements.filter(x => x.scheme === 1);
    }

    own.value.contentWeShare = sZeroForm.value.length > 0;
    own.value.contentTheVendorShares = sOneForm.value.length > 0;
});


const emits = defineEmits(['update:modelValue', 'close']);

const isValid = computed(() => !!own.value.name && hasValidLimit(own.value) && isValidElements(own.value.elements));
const isPosting = ref(false);

const submit = async () => {
    if (!isValid) {
        return;
    }

    if (isPosting.value) {
        return;
    }

    isPosting.value = true;

    const { data } = await figurePostPutOut('/global/data/components', {
        id: own.value.id,
        payload: own.value
    });

    store.refershLookups();

    isPosting.value = false;

    emits('update:modelValue', {
        old: own.value,
        new: data
    });

    emits('close');
};


const sZeroInputsRef = ref()
const sZeroFormRef = ref()

const sZeroInputs = computed(() => store.enums.ElementType.map(x => ({
    uid: uuidv4(),
    type: x.value,
    name: x.label,
    inputCount: 0,
    scheme: 0,
    responseLimit: null,
    values: [],
})))

const sZeroForm = ref([])

watch([sZeroInputsRef, sZeroFormRef], ([el1, el2]) => {
    if (el1 && el2) {

        useDraggable(el1, sZeroInputs, {
            animation: 150,
            group: { name: 'zero', pull: 'clone', put: false },
            sort: false,
            clone: (original) => {
                return { ...original }
            }
        })

        useDraggable(el2, sZeroForm, { animation: 150, group: 'zero' })
    }
})

const sOneInputsRef = ref()
const sOneFormRef = ref()

const sOneInputs = computed(() => store.enums.ElementType.map(x => ({
    uid: uuidv4(),
    type: x.value,
    name: x.label,
    inputCount: 0,
    scheme: 1,
    responseLimit: null,
    values: [],
})))

const sOneForm = ref([])

watch([sOneInputsRef, sOneFormRef], ([el1, el2]) => {
    if (el1 && el2) {

        useDraggable(el1, sOneInputs, {
            animation: 150,
            group: { name: 'one', pull: 'clone', put: false },
            sort: false,
            clone: (original) => {
                return { ...original }
            }
        })

        useDraggable(el2, sOneForm, { animation: 150, group: 'one' })
    }
})

watch([sZeroForm, sOneForm], ([zero, one]) => {
    own.value.elements = [...zero, ...one]
}, { deep: true })


</script>

<template>

    <ItemFormLayout :item="own" :title="t('common.requirement')" :icon="icons.package" @close="emits('close')">


        <template v-if="own.elements" #item>
            <Transition mode="out-in">
                <div class="w-full space-y-3">
                    <Input type="name" :placeholder="t('common.requirementExample')" v-model="own.name" />

                    <CheckCard :isChecked="own.contentWeShare" @update:modelValue="v => own.contentWeShare = v" :title="t('common.requirementBuyerInputTitle', { x: own.name })" :description="t('common.requirementBuyerInputDescription')" :checkIcon="icons.user">

                        <template v-if="own.contentWeShare" #under>

                            <div class="flex flex-row gap-2">

                                <div class="flex flex-col w-1/8 shrink-0 my-2.5">
                                    <span class="text-center">{{ t('common.inputs') }}</span>
                                    <div ref="sZeroInputsRef" class="flex flex-col shrink-0 p-4 space-y-2.5">
                                        <Button v-for="(item, index) in sZeroInputs" :key="item.uid" class="cursor-grab p-3" variant="outline">
                                            {{ item.name }}
                                        </Button>
                                    </div>
                                </div>

                                <div class="flex flex-col gap-2 overflow-auto border rounded-md border-dashed w-full p-4">
                                    <Transition mode="out-in">
                                        <span v-if="sZeroForm.length === 0" class="text-center">{{ t('common.dragAndDropInputs') }}</span>
                                    </Transition>
                                    <div ref="sZeroFormRef" class="h-full space-y-0.5">
                                        <div v-for="(item, index) in sZeroForm" :key="item.uid" class="flex flex-row items-center gap-1">
                                            <ElementValue :element="item" :readonly="true" />
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <Popover>
                                                        <PopoverTrigger as-child>
                                                            <Button size="icon" variant="ghost">
                                                                <Icon :icon="icons.settings" />
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent class="w-60">
                                                            <div class="grid gap-4">
                                                                <div class="space-y-2">
                                                                    <h4 class="font-medium leading-none">
                                                                        {{ t('common.settings') }}
                                                                    </h4>
                                                                    <p class="text-sm text-muted-foreground">
                                                                        {{ t('common.setInputSettings') }}
                                                                    </p>
                                                                </div>
                                                                <div class="grid gap-2">
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="width">{{ t('common.name') }}</Label>
                                                                        <Input v-model="item.name" class="col-span-2 h-8" />
                                                                    </div>
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="maxWidth">{{ t('common.howManyDoYouWant') }}</Label>
                                                                        <Dropdown :items="store.enums.InputCount" :title="t('common.howManyDoYouWant')" :icon="icons.slidesH" :modelValue="item.inputCount" @update:modelValue="v => item.inputCount = v" />
                                                                    </div>
                                                                    <Transition mode="out-in">
                                                                        <div v-if="item.inputCount === 1" class="grid grid-cols-3 items-center gap-4">
                                                                            <Label for="height">{{ t('common.limit') }}</Label>
                                                                            <Number :title="t('common.limit')" :modelValue="item.responseLimit" @update:modelValue="v => item.responseLimit = v" :min="1" :step="1" />
                                                                        </div>
                                                                    </Transition>
                                                                </div>
                                                            </div>
                                                        </PopoverContent>
                                                    </Popover>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    {{ t('common.settings') }}
                                                </TooltipContent>
                                            </Tooltip>
                                            <ConfirmClose @close="sZeroForm.splice(index, 1)" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>

                    </CheckCard>

                    <CheckCard :isChecked="own.contentTheVendorShares" @update:modelValue="v => own.contentTheVendorShares = v" :title="t('common.requirementVendorInputTitle', { x: own.name })" :description="t('common.requirementVendorInputDescription')" :checkIcon="icons.vendors">

                        <template v-if="own.contentTheVendorShares" #under>

                            <div class="flex flex-row gap-2">

                                <div class="flex flex-col w-1/8 shrink-0 my-2.5">
                                    <span class="text-center">{{ t('common.inputs') }}</span>
                                    <div ref="sOneInputsRef" class="flex flex-col shrink-0 p-4 space-y-2.5">
                                        <Button v-for="(item, index) in sOneInputs" :key="item.uid" class="cursor-grab p-3" variant="outline">
                                            {{ item.name }}
                                        </Button>
                                    </div>
                                </div>

                                <div class="flex flex-col gap-2 overflow-auto border rounded-md border-dashed w-full p-4">
                                    <Transition mode="out-in">
                                        <span v-if="sOneForm.length === 0" class="text-center">{{ t('common.dragAndDropInputs') }}</span>
                                    </Transition>
                                    <div ref="sOneFormRef" class="h-full space-y-0.5">
                                        <div v-for="(item, index) in sOneForm" :key="item.uid" class="flex flex-row items-center gap-1">
                                            <ElementValue :element="item" :readonly="true" />
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <Popover>
                                                        <PopoverTrigger as-child>
                                                            <Button size="icon" variant="ghost">
                                                                <Icon :icon="icons.settings" />
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent class="w-60">
                                                            <div class="grid gap-4">
                                                                <div class="space-y-2">
                                                                    <h4 class="font-medium leading-none">
                                                                        {{ t('common.settings') }}
                                                                    </h4>
                                                                    <p class="text-sm text-muted-foreground">
                                                                        {{ t('common.setInputSettings') }}
                                                                    </p>
                                                                </div>
                                                                <div class="grid gap-2">
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="width">{{ t('common.name') }}</Label>
                                                                        <Input v-model="item.name" class="col-span-2 h-8" />
                                                                    </div>
                                                                    <div class="grid grid-cols-3 items-center gap-4">
                                                                        <Label for="maxWidth">{{ t('common.howManyDoYouWant') }}</Label>
                                                                        <Dropdown :items="store.enums.InputCount" :title="t('common.howManyDoYouWant')" :icon="icons.slidesH" :modelValue="item.inputCount" @update:modelValue="v => item.inputCount = v" />
                                                                    </div>
                                                                    <Transition mode="out-in">
                                                                        <div v-if="item.inputCount === 1" class="grid grid-cols-3 items-center gap-4">
                                                                            <Label for="height">{{ t('common.limit') }}</Label>
                                                                            <Number :title="t('common.limit')" :modelValue="item.responseLimit" @update:modelValue="v => item.responseLimit = v" :min="1" :step="1" />
                                                                        </div>
                                                                    </Transition>
                                                                </div>
                                                            </div>
                                                        </PopoverContent>
                                                    </Popover>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    {{ t('common.settings') }}
                                                </TooltipContent>
                                            </Tooltip>
                                            <ConfirmClose @close="sOneForm.splice(index, 1)" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>

                    </CheckCard>
                    <div class="flex flex-row items-center justify-start gap-2 text-muted-foreground">
                        <Icon :icon="icons.info" />
                        <span>{{ t('common.commentsAreEnabledByDefault') }}</span>
                    </div>
                </div>

            </Transition>
        </template>

        <template #controls>
            <ProcessingButton @click="submit" :disabled="isPosting || !isValid" :processing="isPosting" :text="t('common.save')" />
        </template>


    </ItemFormLayout>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>