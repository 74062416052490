<script setup>

import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { isUserAuthenticated } from '@/utils/auth';

import { removeToken } from '@/utils/auth';
import { post } from '@/utils/http';

const route = useRoute();
const router = useRouter();
const store = useGlobalState();

onMounted(() => {
  if (!isUserAuthenticated()) {
    return;
  } else {
    store.fetchLookups();
  }

  post('auth/token/validate')
    .then(({ data }) => {
      if (!data.isValid) {
        removeToken();
        router.push({ name: 'login' });
        return;
      }
    })

});

</script>

<template>
  <main>
    <router-view v-slot="{ Component }">
      <Transition mode="out-in">
        <div :key="route.name">
          <component :is="Component" />
        </div>
      </Transition>
    </router-view>
  </main>
  <Toaster />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
