import { jwtDecode } from 'jwt-decode';


function isTokenValid(token) {
  if (!token) {
    return false;
  }

  try {
    const { role, access, expiration } = getDecodedToken();

    const currentDate = new Date();
    const hasExpired = expiration * 1000 < currentDate.getTime();

    return !hasExpired && !!role && !!access;
  } catch {
    removeToken();
    return false;
  }
}

export function getDecodedToken() {
  try {
    const token = getToken();
    const decoded = jwtDecode(token);

    return {
      id: decoded.sub,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      email: decoded.email,
      tenantId: decoded.typ,
      access: decoded.acr,
      role: decoded.prn,
      subscription: JSON.parse(decoded.unique_name),
      expiration: decoded.exp,
      host: decoded.iss,
      audience: decoded.aud,
    }
  } catch {
    removeToken();
    return null;
  }
}

export function getCurrentSubscription() {
  const { subscription, tenantId } = getDecodedToken();

  const sub = subscription.find(s => s.TenantId === tenantId);
  if (!sub) {
    return null;
  }

  return {
    ...sub,
    isBuyer: sub.Entity === 0,
    isVendor: sub.Entity === 1,
  };
}

export function getAllSubscriptions() {
  const { subscription } = getDecodedToken();

  return subscription.map(x => ({
    ...x,
    isBuyer: x.Entity === 0,
    isVendor: x.Entity === 1,
  }));
}

export function generateAccess(access) {
  let a = [];

  if (access.user === true) {
    a.push('User');
  }

  if (access.admin === true) {
    a.push('Admin');
  }
  
  return a;
}

export function generateRole(role) {
  const r = [];

  if (role.buyer === true) {
    r.push('Buyer');
  }

  if (role.vendor === true) {
    r.push('Vendor');
  }
  
  return r;
}

export function isUserAuthenticated() {
  const token = localStorage.getItem('token');
  return isTokenValid(token);
}

export function setToken(token) {
  localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function setReturnUrl(url) {
  if (!url) {
    return;
  }

  localStorage.setItem('returnUrl', url);
}

export function getReturnUrl() {
  return localStorage.getItem('returnUrl');
}

export function removeReturnUrl() {
  localStorage.removeItem('returnUrl');
}