<script setup>

import { ref, watch } from 'vue';
import { createReusableTemplate, useMediaQuery } from '@vueuse/core';
import { VisuallyHidden } from 'radix-vue';

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    showClose: {
        type: Boolean,
        default: false,
    },
    trapFocus: {
        type: Boolean,
        default: true,
    },
    disableOutsidePointerEvents: {
        type: Boolean,
        default: true,
    },
});

const emits = defineEmits(['update:open']);

const [ControlsTemplate, ControlsGrid] = createReusableTemplate();
const [FooterTemplate, FooterGrid] = createReusableTemplate();
const isDesktop = useMediaQuery('(min-width: 768px)');

const ownOpen = ref(props.open);

const handleOpenChange = (value) => {
    ownOpen.value = value;
    emits('update:open', value);
};

watch(() => props.open, (value) => {
    handleOpenChange(value);
});

</script>

<template>
    <Dialog v-if="isDesktop" v-model:open="ownOpen" @update:open="o => handleOpenChange(o)">
        <DialogContent :showClose="props.showClose" :trapFocus="props.trapFocus" :disableOutsidePointerEvents="props.disableOutsidePointerEvents" class="sm:max-w-[1200px] p-0 min-h-fit">
            <VisuallyHidden>
                <DialogTitle></DialogTitle>
            </VisuallyHidden>
            <VisuallyHidden>
                <DialogDescription></DialogDescription>
            </VisuallyHidden>
            <ControlsGrid />
            <template v-if="$slots.footer">
                <DialogFooter>
                    <FooterGrid />
                </DialogFooter>
            </template>
        </DialogContent>
    </Dialog>

    <Drawer v-else v-model:open="ownOpen" @update:open="o => handleOpenChange(o)">
        <DrawerContent :trapFocus="props.trapFocus" :disableOutsidePointerEvents="props.disableOutsidePointerEvents">
            <VisuallyHidden>
                <DrawerTitle></DrawerTitle>
            </VisuallyHidden>
            <VisuallyHidden>
                <DrawerDescription></DrawerDescription>
            </VisuallyHidden>
            <ControlsGrid />
            <template v-if="$slots.footer">
                <DrawerFooter>
                    <FooterGrid />
                </DrawerFooter>
            </template>
        </DrawerContent>
    </Drawer>

    <ControlsTemplate>
        <Transition mode="out-in">
            <template v-if="$slots.controls">
                <ScrollArea class="max-h-[80dvh]">
                    <div class="flex flex-col p-0">
                        <slot name="controls"></slot>
                    </div>
                </ScrollArea>
            </template>
        </Transition>
    </ControlsTemplate>

    <FooterTemplate>
        <Transition mode="out-in">
            <template v-if="$slots.footer">
                <slot name="footer"></slot>
            </template>
        </Transition>
    </FooterTemplate>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>