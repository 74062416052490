<script setup>

import { ref } from 'vue';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { useI18n } from 'vue-i18n'

import ComponentForm from '../Forms/ComponentForm.vue';

import { icons } from '@/utils/icons';

const { t } = useI18n();
const store = useGlobalState();

const currentComponent = ref(null);

const emits = defineEmits(['add']);

const updateComponent = (v) => {
    currentComponent.value = v;
    add();
}

const addNew = (v) => {
    if (!store.lookups.components.find(c => c.id === v.id)) {
        store.lookups.components.push(v);
    }

    currentComponent.value = v.id;
    add();
}

const add = () => {
    if (!currentComponent.value) {
        return;
    }

    const component = store.lookups.components.find(c => c.id === currentComponent.value);
    const copy = JSON.parse(JSON.stringify(component));

    emits('add', copy);

    closeModal();
}

const isDropDownOpen = ref(false);
const isModalOpen = ref(false);
const componentItem = ref(null);

const createComponent = () => {
    componentItem.value = {};
    isModalOpen.value = true;
}

const closeModal = () => {
    isModalOpen.value = false;
    componentItem.value = {};
}

</script>

<template>

    <div class="flex flex-row items-center gap-2">
        <Transition mode="out-in">
            <Dropdown v-if="store.types.components.length > 0" :items="store.types.components" :isOpen="isDropDownOpen" :title="t('common.add')" :icon="icons.input" :modelValue="currentComponent" @update:modelValue="v => updateComponent(v)" @update:isOpen="v => isDropDownOpen = v">
                <template #empty>
                    <div class="flex flex-col items-center gap-2">
                        <span>
                            {{ t('common.noResultsFound') }}
                        </span>
                        <Button class="border-dashed w-fit" @click="createComponent">
                            {{ t('common.create') }} {{ t('common.new') }}
                        </Button>
                    </div>
                </template>
            </Dropdown>
            <Button v-else class="border-dashed" @click="createComponent">
                {{ t('common.create') }} {{ t('common.requirement') }}
            </Button>
        </Transition>
        <Transition mode="out-in">
            <Button v-if="currentComponent" variant="secondary" size="icon" @click="add">
                <Icon :icon="icons.plus" />
            </Button>
        </Transition>
    </div>

    <Modal :open="isModalOpen" @update:open="v => isModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div class="flex flex-col grow">
                    <ComponentForm :item="componentItem" @close="closeModal" @update:modelValue="v => addNew(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>