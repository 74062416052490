export function beep(volume) {

    return new Promise((resolve, reject) => {

        volume = volume || 100;

        try {
            const soundSource = '/assets/chat/pop.mp3';
            const sound = new Audio(soundSource);
            sound.volume = volume / 100;

            sound.onended = () => {
                resolve();
            };

            sound.play();
        } catch (error) {
            reject(error);
        }
    });
}
