import { createRouter, createWebHashHistory } from 'vue-router'
import queryString from 'query-string';
import { isUserAuthenticated, removeToken, setReturnUrl, getReturnUrl, removeReturnUrl, getDecodedToken, generateAccess, generateRole } from '@/utils/auth'

import { icons } from '@/utils/icons';

import Login from './views/auth/login.vue'
import Register from './views/auth/register.vue'
import Enter from './views/auth/enter.vue'
import Ohayo from './views/auth/ohayo.vue'
import Unauthorized from './views/auth/unauthorized.vue'

const supplierRoute = (route) => `/hi${route}`;

const routes = [
  {
    path: '/Login',
    component: Login,
    name: 'login',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/Enter/:hash/:token',
    component: Enter,
    name: 'enter',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/ohayo/:tenant/:hash/:token',
    component: Ohayo,
    name: 'ohayo',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    name: 'unauthorized',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },
  {
    path: '/Register',
    component: Register,
    name: 'register',
    meta: {
      requiresAuth: false,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true })
    },
  },

  {
    path: '/scratch-my-back',
    component: () => import('./views/_dev/Scratch.vue'),
    name: 'scratch-my-back',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true, vendor: true }),
      isDev: true
    },
  },

  {
    path: '/',
    component: () => import('./views/buyers/dashboards/Home.vue'),
    name: 'buyer-home',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true }),
    },
  },
  {
    path: '/create-package/:templateId?/project/:projectId?',
    component: () => import('./views/buyers/dashboards/packs/Create.vue'),
    props: true,
    name: 'buyer-create-package',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true }),
    },
  },
  {
    path: '/projects/:id',
    component: () => import('./views/buyers/dashboards/projects/Work.vue'),
    name: 'buyer-manage-project',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true }),    
    },
  },
  {
    path: '/packages/:id',
    component: () => import('./views/buyers/dashboards/packs/Work.vue'),
    name: 'buyer-manage-package',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ buyer: true })
    },
  },

  {
    path: '/tasks',
    component: () => import('./views/buyers/dashboards/tasks/Work.vue'),
    name: 'buyer-tasks',
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'Tasks',
      icon: icons.tasks,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },
  {
    path: '/packages',
    name: 'package-templates',
    component: () => import('./views/buyers/settings/packs/ItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'RFx Templates',
      icon: icons.templates,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },
  {
    path: '/automations',
    name: 'automations',
    component: () => import('./views/buyers/settings/automations/ItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'Automations',
      icon: icons.automations,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },
  {
    path: '/vendors',
    name: 'vendors',
    component: () => import('./views/buyers/settings/vendors/ItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'Suppliers',
      icon: icons.vendors,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('./views/buyers/settings/users/ItemsDashboard.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
      title: 'Users',
      icon: icons.users,
      access: generateAccess({ admin: true }),
      role: generateRole({ buyer: true })
    },
  },


  {
    path: supplierRoute(""),
    component: () => import('./views/vendors/dashboards/Home.vue'),
    name: 'vendor-home',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ vendor: true })
    },
  },
  {
    path: supplierRoute("/packages/:id/work"),
    component: () => import('./views/vendors/dashboards/packs/Work.vue'),
    name: 'vendor-work-package',
    meta: {
      requiresAuth: true,
      access: generateAccess({ user: true, admin: true }),
      role: generateRole({ vendor: true })
    },
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

router.beforeEach((to, from) => {

  try {
    const { requiresAuth, access: navAccess, role: navRole, isDev } = to.meta;
    const isAuthenticated = isUserAuthenticated();
    const returnUrl = getReturnUrl();

    if (to.query?.ReturnUrl) {
      setReturnUrl(to.query?.ReturnUrl);
    }

    if (isAuthenticated) {

      if (isDev === true && process.env.NODE_ENV !== 'development') {
        return { name: 'unauthorized' };
      }

      const { access: tokenAccess, role: tokenRole } = getDecodedToken();

      const hasNoRoles = !tokenRole || navRole.length === 0;
      const roleNotMatched = navRole && navRole.length > 0 && !navRole.some(r => tokenRole.includes(r));

      if ((hasNoRoles || roleNotMatched) && to.name !== 'unauthorized') {
        return { name: 'unauthorized' };
      }

      const userAccess = tokenAccess.split(',').map(a => a.trim());
      const hasNoAccess = !tokenAccess || userAccess.length === 0;
      const accessNotMatched = navAccess && navAccess.length > 0 && !navAccess.some(a => userAccess.includes(a));

      if ((hasNoAccess || accessNotMatched) && to.name !== 'unauthorized') {
        return { name: 'unauthorized' };
      }

      if (returnUrl) {

        removeReturnUrl();
        const [path, q] = returnUrl.split('?');
        const query = q ? queryString.parse(q) : {};

        if (to.path !== path) {
          return { path: path, query: query };
        }
      }
    } else {
      if (requiresAuth && to.name !== 'login') {
        removeToken();

        if (from.fullPath !== to.fullPath) {
          return { name: 'login', query: { ReturnUrl: to.fullPath } };
        } else {
          return { name: 'login' };
        }
      }
    }

  } catch (error) {
    console.error(error);

    if (to.name !== 'login') {

      removeToken();
      return { name: 'login' };
    }
  }
});



export default router