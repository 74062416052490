<script setup>

import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';

import { useGlobalState } from '@/views/_shared/stores/GlobalState';

import { isValidElements } from '@/views/buyers/settings/packs/helpers/packUtils';

import { icons } from '@/utils/icons';
import { useDraggable } from 'vue-draggable-plus'

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {},
    index: {},
    enableCopy: {
        default: true,
        type: Boolean
    },
    enableInput: {
        default: true,
        type: Boolean
    },
    enableRemove: {
        default: true,
        type: Boolean
    }
});

const emits = defineEmits(['copy', 'remove']);
const isEditing = ref(false);
const editScheme = ref(null);
const itemCopy = ref(null);

onMounted(() => {
    props.item.elements.forEach(element => {
        element.values = !element.values || element.values.length === 0 ? [{ uid: uuidv4(), value: null }] : element.values;
    });
});

watch(() => props.item, (newVal, _) => {
    if (!newVal) {
        return;
    }

    newVal.elements.forEach(element => {
        element.values = !element.values || element.values.length === 0 ? [{ uid: uuidv4(), value: null }] : element.values;
    });
});

const edit = (scheme) => {
    isEditing.value = !isEditing.value;

    if (!isEditing.value) {
        cancelEdit();
    } else {
        itemCopy.value = createCopy();
        editScheme.value = scheme;
        form.value = itemCopy.value.elements.filter(x => x.scheme === scheme);
    }
}

const cancelEdit = () => {

    isEditing.value = false;
    itemCopy.value = null;
    editScheme.value = null;
    form.value = [];
}

const saveEdit = async () => {

    props.item.elements = itemCopy.value.elements;

    await nextTick();

    itemCopy.value = null;
    isEditing.value = false;
}

const copy = () => {
    const copy = createCopy();
    emits('copy', { copy, index: props.index });
}

const createCopy = () => {
    const copy = JSON.parse(JSON.stringify(props.item));
    copy.uid = uuidv4();

    copy.elements.forEach(element => {
        element.values = element.values.map(v => ({ ...v, uid: uuidv4() }));
    });

    return copy;
}

const inputsRef = ref()
const formRef = ref()

const inputs = computed(() => store.enums.ElementType.map(x => ({
    uid: uuidv4(),
    type: x.value,
    name: x.label,
    inputCount: 0,
    scheme: editScheme.value,
    responseLimit: null,
    values: [{ uid: uuidv4(), value: null }],
})))

const form = ref([])

watch([inputsRef, formRef], ([el1, el2]) => {
    if (el1 && el2) {

        useDraggable(el1, inputs, {
            animation: 150,
            group: { name: 'zero', pull: 'clone', put: false },
            sort: false,
            clone: (original) => {
                return { ...original }
            }
        })

        useDraggable(el2, form, { animation: 150, group: 'zero' })
    }
})

watch([form], ([zero]) => {
    if (itemCopy.value) {
        itemCopy.value.elements = [...zero, ...itemCopy.value.elements.filter(x => x.scheme !== editScheme.value)]
    }
}, { deep: true })


</script>

<template>

    <Transition mode="out-in">

        <div v-if="item" class="w-full px-3 py-2 border rounded-md border-dashed space-y-2">
            <div class="flex flex-row items-center justify-between font-semibold">
                <span>
                    {{ props.item.name }}
                </span>
                <div class="flex flex-row items-center gap-1">
                    <Tooltip v-if="enableCopy">
                        <TooltipTrigger>
                            <Button variant="outline" @click="copy" size="icon">
                                <Icon :icon="icons.copy" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.copy') }}
                        </TooltipContent>
                    </Tooltip>
                    <ConfirmClose v-if="enableRemove" @close="emits('remove', { item: props.item, index: props.index })" />
                </div>
            </div>

            <Transition mode="out-in">

                <div v-if="isEditing" class="py-2">

                    <div class="flex flex-row gap-2">

                        <div class="flex flex-col w-1/6 shrink-0 my-2.5">

                            <span class="text-center">{{ t('common.inputs') }}</span>

                            <div ref="inputsRef" class="flex flex-col shrink-0 p-4 space-y-2.5">
                                <Button v-for="(item, index) in inputs" :key="item.uid" class="cursor-grab p-3" variant="outline">
                                    {{ item.name }}
                                </Button>
                            </div>
                        </div>


                        <div class="flex flex-col gap-2 overflow-auto border rounded-md border-dashed w-full p-4">
                            <div class="font-medium text-center mb-2">{{ editScheme === 0 ? t('common.requirementBuyerInputDescription') : t('common.requirementVendorInputDescription') }}</div>
                            <span class="text-center">{{ t('common.dragAndDropInputs') }}</span>

                            <div ref="formRef" class="h-full space-y-0.5">
                                <div v-for="(item, index) in form" :key="item.uid" class="flex flex-row items-center gap-1">
                                    <ElementValue :element="item" :readonly="true" />
                                    <Tooltip>
                                        <TooltipTrigger>
                                            <Popover>
                                                <PopoverTrigger as-child>
                                                    <Button size="icon" variant="ghost">
                                                        <Icon :icon="icons.settings" />
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent class="w-60">
                                                    <div class="grid gap-4">
                                                        <div class="space-y-2">
                                                            <h4 class="font-medium leading-none">
                                                                {{ t('common.settings') }}
                                                            </h4>
                                                            <p class="text-sm text-muted-foreground">
                                                                {{ t('common.setInputSettings') }}
                                                            </p>
                                                        </div>
                                                        <div class="grid gap-2">
                                                            <div class="grid grid-cols-3 items-center gap-4">
                                                                <Label for="width">{{ t('common.name') }}</Label>
                                                                <Input v-model="item.name" class="col-span-2 h-8" />
                                                            </div>
                                                            <div class="grid grid-cols-3 items-center gap-4">
                                                                <Label for="maxWidth">{{ t('common.howManyDoYouWant') }}</Label>
                                                                <Dropdown :items="store.enums.InputCount" :title="t('common.howManyDoYouWant')" :icon="icons.slidesH" :modelValue="item.inputCount" @update:modelValue="v => item.inputCount = v" />
                                                            </div>
                                                            <Transition mode="out-in">
                                                                <div v-if="item.inputCount === 1" class="grid grid-cols-3 items-center gap-4">
                                                                    <Label for="height">{{ t('common.limit') }}</Label>
                                                                    <Number :title="t('common.limit')" :modelValue="item.responseLimit" @update:modelValue="v => item.responseLimit = v" :min="1" :step="1" />
                                                                </div>
                                                            </Transition>
                                                        </div>
                                                    </div>
                                                </PopoverContent>
                                            </Popover>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {{ t('common.settings') }}
                                        </TooltipContent>
                                    </Tooltip>
                                    <ConfirmClose @close="form.splice(index, 1)" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row items-center justify-between mt-4">

                        <Button variant="secondary" @click="cancelEdit()">
                            {{ t('common.cancel') }}
                        </Button>

                        <Button variant="default" :disabled="!isValidElements(itemCopy.elements)" @click="saveEdit()">
                            {{ t('common.save') }}
                        </Button>
                    </div>

                </div>
                <div v-else>
                    <Transition mode="out-in">
                        <div v-if="props.enableInput" class="flex flex-col w-full">

                            <ElementForm :items="props.item.elements.filter(x => x.scheme === 0)" />

                        </div>
                    </Transition>
                    <div class="flex flex-col items-start gap-2 w-full">

                        <Card class="flex flex-row items-center gap-x-3 space-y-0 rounded-md border-dashed p-4 me-2 grow shrink-0 cursor-pointer w-full" @click="edit(0)">
                            <div class="flex flex-col gap-2 grow">
                                <div class="font-medium">{{ t('common.requirementBuyerInputDescription') }}</div>
                                <div class="flex flex-row items-center gap-1">
                                    <Badge variant="outline">
                                        <Icon :icon="icons.user" class="h-4 w-4" />
                                    </Badge>
                                    <Badge v-for="(e, index) in props.item.elements.filter(x => x.scheme === 0)" :key="index" variant="secondary" class="w-fit">
                                        {{ e.name }}
                                    </Badge>
                                    <span v-if="props.item.elements.filter(x => x.scheme === 0).length === 0">{{ t('common.NA') }}</span>
                                </div>
                            </div>
                            <Tooltip>
                                <TooltipTrigger>
                                    <Button variant="outline" size="icon">
                                        <Icon :icon="icons.edit" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {{ t('common.edit') }}
                                </TooltipContent>
                            </Tooltip>

                        </Card>

                        <Card class="flex flex-row items-center gap-x-3 space-y-0 rounded-md border-dashed p-4 me-2 grow shrink-0 cursor-pointer w-full" @click="edit(1)">
                            <div class="flex flex-col gap-2 grow">
                                <div class="font-medium">{{ t('common.requirementVendorInputDescription') }}</div>
                                <div class="flex flex-row items-center gap-1">
                                    <Badge variant="outline">
                                        <Icon :icon="icons.vendors" class="h-4 w-4" />
                                    </Badge>
                                    <Badge v-for="(e, index) in props.item.elements.filter(x => x.scheme === 1)" :key="index" variant="secondary" class="w-fit">
                                        {{ e.name }}
                                    </Badge>
                                    <span v-if="props.item.elements.filter(x => x.scheme === 1).length === 0">{{ t('common.NA') }}</span>
                                </div>
                            </div>
                            <Tooltip>
                                <TooltipTrigger>
                                    <Button variant="outline" size="icon">
                                        <Icon :icon="icons.edit" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {{ t('common.edit') }}
                                </TooltipContent>
                            </Tooltip>
                        </Card>

                        <Transition mode="out-in">
                            <slot v-if="$slots.controls" name="controls"></slot>
                        </Transition>

                    </div>
                </div>
            </Transition>
        </div>
    </Transition>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>