<script setup>

import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'

const { t } = useI18n();
const router = useRouter();

import { navigateHome } from "@/utils/nav";

const goBack = () => {
    navigateHome(router);
    return;
}

</script>

<template>
    <ScrollArea class="h-full w-full">
        <div class="flex flex-row items-center justify-center h-svh">
            <Card class="flex flex-col justify-center space-y-6 sm:w-[350px] p-4">
                <div class="flex flex-col space-y-2 text-center items-center">
                    <figure class="mb-3">
                        <AlinorLogo />
                    </figure>
                    {{ t('views.auth.unauthorized.title') }}
                    <p class="text-sm text-muted-foreground">
                        {{ t('views.auth.unauthorized.description') }}
                    </p>
                    <Button variant="default" @click="goBack" class="w-full">
                        {{ t('views.auth.unauthorized.goBack') }}
                    </Button>
                    <span class="text-muted-foreground text-center">{{ t('common.or') }} <router-link :to="{ name: 'login' }">{{ t('views.auth.login.signIn') }}</router-link></span>
                </div>
            </Card>
        </div>
    </ScrollArea>
</template>
