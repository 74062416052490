<script setup>

import { icons } from '@/utils/icons';

const props = defineProps({
    isChecked: {
        type: Boolean,
        default: false,
    },
    title: {
        type: String,
    },
    description: {
        type: String,
        default: ''
    },
    checkIcon: {
        type: String,
        default: icons.check
    },
    unCheckIcon: {
        type: String,
        default: ''
    },
    readonly: {
        type: Boolean,
        default: false
    }
});

const emits = defineEmits(['update:modelValue']);

const clickHandler = () => {
    if (props.readonly){
        return;
    }

    emits('update:modelValue', !props.isChecked);
}

</script>

<template>
    <div class="flex flex-col w-full">
        <div class="flex flex-row items-center justify-between gap-2">
            <Card class="flex flex-row items-center justify-between space-y-0 p-4 rounded-md border hover:cursor-pointer grow w-full" @click="clickHandler">
                <div class="flex flex-col gap-3">
                    <div class="flex flex-row items-center gap-2">
                        <div class="flex items-center">
                            <Checkbox :disabled="props.readonly ? true : false" :checked="props.isChecked" />
                        </div>
                        <div class="space-y-1 leading-none flex flex-row w-full justify-between items-center">
                            <div class="grow">
                                <div class="font-medium">{{ props.title }}</div>
                                <div v-if="props.description" class="text-muted-foreground">
                                    {{ description }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Transition mode="out-in">
                        <div v-if="$slots.inside">
                            <slot name="inside"></slot>
                        </div>
                    </Transition>
                </div>
                <Transition mode="out-in">
                    <Icon :icon="props.checkIcon" v-if="props.isChecked && !!props.checkIcon" class="h-4 w-4" />
                    <Icon :icon="props.unCheckIcon" v-else-if="!props.isChecked && !!props.unCheckIcon" class="h-4 w-4" />
                </Transition>
            </Card>

            <Transition mode="out-in">
                <template v-if="$slots.after && props.isChecked">
                    <div class="border rounded-md border-dashed p-2 gap-x-3 space-y-0 shrink-0">
                        <slot name="after"></slot>
                    </div>
                </template>
            </Transition>
        </div>
        <Transition mode="out-in">
            <div v-if="$slots.under" class="border rounded-md border-dashed p-4 mt-2">
                <slot name="under"></slot>
            </div>
        </Transition>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>