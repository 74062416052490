<script setup>

import { ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n'
import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { useRouter } from 'vue-router';

import { icons } from '@/utils/icons';
import ProjectForm from '@/views/_shared/Components/Forms/ProjectForm.vue';

const { t } = useI18n();
const store = useGlobalState();
const router = useRouter();

const props = defineProps({
    title: {
        type: String,
        Required: true
    },
    templateId: {
        type: Number,
        default: null
    },
    projectId: {
        type: Number,
        default: null
    },
    variant: {
        type: String,
        default: 'default'
    },
    isOpen: {},
    settings: {
        type: Object,
        default: {
            showProject: true,
            showTemplate: true
        }
    }
});

const isSelectorOpen = ref(props.isOpen ?? false);

const handleSelectorOpen = (value) => {
    if (value) {
        if (store.types.templates.length === 0) {
            selectTemplate({ detail: { value: null } });
            return;
        }
    }

    isSelectorOpen.value = value;
}

const selectTemplate = (e) => {
    const value = e.detail.value;
    const template = store.lookups.templates.find(t => t.id === value);
    const tId = template?.id;
    isSelectorOpen.value = false;

    router.push({ name: 'buyer-create-package', params: { templateId: tId, projectId: props.projectId } });
}

const isProjectModalOpen = ref(false);
const projectITem = ref(null);

const createProject = () => {
    projectITem.value = {};
    isProjectModalOpen.value = true;
    isSelectorOpen.value = false;
}

const closeProjectModal = () => {
    isProjectModalOpen.value = false;
    projectITem.value = {};
}

const addNewProject = (v) => {
    if (!store.lookups.projects.find(c => c.id === v.id)) {
        store.lookups.projects.push(v);
    }

    closeProjectModal();
    router.push({ name: 'buyer-manage-project', params: { id: v.id } });
}

watchEffect(() => {
    if (props.isOpen === false) {
        handleSelectorOpen(false);
    }
});

</script>

<template>
    <Tooltip>
        <TooltipTrigger>
            <Button @click="() => handleSelectorOpen(true)" size="icon" :variant="props.variant">
        <Icon :icon="icons.plus" w="w-4" h="h-4" />
    </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
            {{ t('common.create') }}
        </TooltipContent>
    </Tooltip>
      
    <CommandDialog :open="isSelectorOpen" @update:open="o => handleSelectorOpen(o)">
        <CommandInput :placeholder="t('common.typeACommandOrSearch')" />
        <CommandList>
            <CommandEmpty>
                <div class="flex flex-col gap-2">
                    <span>
                        {{ t('common.noResultsFound') }}
                    </span>
                    <span>
                        <Button class="border-dashed" @click="selectTemplate({ detail: { value: null } })">
                            {{ t('common.createFromScratch') }}
                        </Button>
                    </span>
                </div>
            </CommandEmpty>
            <template v-if="$slots.top">
                <slot name="top"></slot>
            </template>
            <Transition mode="out-in">
                <CommandGroup v-if="settings?.showProject" :heading="t('common.projects')">
                    <CommandItem class="hover:cursor-pointer" @select="createProject" :key="-1" value="project">
                        <Icon :icon="icons.project" class="mr-2 h-4 w-4" />
                        {{ t('common.new') }} {{ t('common.project') }}
                    </CommandItem>
                </CommandGroup>
            </Transition>
            <Transition mode="out-in">
                <CommandGroup v-if="settings?.showTemplate" :heading="t('common.pickTemplate')">
                    <CommandItem v-for="(item, index) in store.types.templates" :key="index" :value="item.value" class="hover:cursor-pointer" @select="selectTemplate">
                        <Icon :icon="icons.package" class="mr-2 h-4 w-4" />
                        {{ item.label }}
                    </CommandItem>
                </CommandGroup>
            </Transition>
            <template v-if="$slots.bottom">
                <slot name="bottom"></slot>
            </template>
        </CommandList>
    </CommandDialog>

    <Modal :open="isProjectModalOpen" @update:open="v => isProjectModalOpen = v">

        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <div v-if="projectITem" class="flex flex-col grow">
                    <ProjectForm :item="projectITem" @close="closeProjectModal" @update:modelValue="v => addNewProject(v.new)" />
                </div>
            </div>
        </template>

    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>