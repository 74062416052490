<script setup>

import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';

const { t } = useI18n();

const props = defineProps({
    item: Object,
    element: Object
});

const fileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileNameParts = fileName.split('-');
    const fileNameWithoutUuid = fileNameParts.slice(0, fileNameParts.length - 1).join('-');
    return fileNameWithoutUuid;
};

</script>

<template>
    <div class="flex flex-row items-center gap-2">

        <span v-if="!item.value" class="text-muted-foreground">{{ t('common.noValue') }}</span>

        <div v-else-if="element.type === 4" class="flex flex-row gap-2 items-center">
            <Tooltip>
                <TooltipTrigger>
                    <Button as="a" variant="outline" class="text-primary" :href="item.value" size="icon">
                        <Icon :icon="icons.download" class="h-4 w-4" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {{ t('common.download') }}
                </TooltipContent>
            </Tooltip>
            <span>{{ fileNameFromUrl(item.value) }}</span>
        </div>

        <audio v-else-if="element.type === 6" :src="item.value" controls></audio>

        <video v-else-if="element.type === 7" :src="item.value" controls></video>

        <video v-else-if="element.type === 8" :src="item.value" controls></video>

        <MarkdownRenderer v-else-if="element.type === 1" :content="item.value" class="p-0" />

        <span v-else-if="element.type === 5">
            <Badge v-if="item.value === '1'" class="w-fit" variant="outline">{{ t('common.yes') }}</Badge>
            <Badge v-else class="w-fit" variant="destructive">{{ t('common.no') }}</Badge>
        </span>

        <span v-else>{{ item.value }}</span>
    </div>
</template>