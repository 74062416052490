<script setup>

import { computed } from 'vue'

const props = defineProps({
    width: {
        type: String,
        default: 'auto'
    },
    height: {
        type: String,
        default: '30px'
    },
    titleSettings: {
        type: Object,
        default: () => ({
            show: true,
            pos: 'right',
            title: 'Alinor'
        })
    }
});

const shouldShowTitle = computed(() => {
    if (!props.titleSettings) {
        return false;
    }

    return props.titleSettings.show;
});

const titlePosition = computed(() => {
    if (!shouldShowTitle.value) {
        return '';
    }

    if (props.titleSettings.pos === 'left') {
        return 'flex-row-reverse gap-2'
    } else if (props.titleSettings.pos === 'right') {
        return 'flex-row gap-2'
    } else if (props.titleSettings.pos === 'top') {
        return 'flex-col-reverse gap-2'
    } else if (props.titleSettings.pos === 'bottom') {
        return 'flex-col gap-2'
    }
});

</script>

<template>
    <div class="flex items-center justify-center" :class="titlePosition">
        <img src="/assets/logos/white+logo.png" alt="Alinor - Procurement and sourcing automated " :style="`display:block; max-width:100%; max-height:${props.height}; width:${props.width};max-width: 100%;`" fetchpriority="high" loading="eager" decoding="async" data-loader="raw">
        <Transition mode="out-in">
            <span class="font-semibold" v-if="shouldShowTitle">{{ props.titleSettings.title }}</span>
        </Transition>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
