<script setup>

import { ref, watchEffect, computed, onUnmounted } from 'vue'
import { usePermission } from '@vueuse/core'
import { useUserMedia } from '@vueuse/core'
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import { icons } from '@/utils/icons';

const emits = defineEmits(['update:recording'])

const { t } = useI18n();

const selectedMicrophone = ref('')
const currentMicrophone = computed(() => selectedMicrophone.value)

const props = defineProps({
    readonly: {
        type: Boolean,
        default: false
    }
})

const { stream, start, stop } = useUserMedia({
    constraints: {
        audio: { deviceId: currentMicrophone }
    },
})

const microphoneAccess = usePermission('microphone')
const mediaRecorder = ref(null)
const audioChunks = ref([])
const isRecording = ref(false)
const isPaused = ref(false)
const showMicrophoneList = ref(false)
const microphones = ref([])
const micIcon = computed(() => isRecording.value ? icons.stop : icons.mic)
const pauseIcon = computed(() => isPaused.value ? icons.mic : icons.pause)
const isGranted = computed(() => microphoneAccess.value === 'granted')
const isCanceling = ref(false)
const url = ref('')

const getMicrophones = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    microphones.value = devices.filter(device => device.kind === 'audioinput')

    if (microphones.value.length > 0 && !selectedMicrophone.value) {
        selectedMicrophone.value = microphones.value[0].deviceId
    }
}

watchEffect(() => {
    if (stream.value && !mediaRecorder.value) {
        mediaRecorder.value = new MediaRecorder(stream.value)

        mediaRecorder.value.ondataavailable = (event) => {
            audioChunks.value.push(event.data)
        }

        mediaRecorder.value.onstop = () => {
            if (audioChunks.value.length === 0) {
                return;
            }

            if (!isCanceling.value) {
                const blob = new Blob(audioChunks.value, { type: 'audio/wav' })
                url.value = window.URL.createObjectURL(blob);
                emits('update:recording', asFile(blob))
            } else {
                isCanceling.value = false
            }

            audioChunks.value = []
            mediaRecorder.value = null
        }
    }
})

const toggleRecording = async () => {
    if (!isRecording.value) {

        try {
            await getMicrophones()
            await start()

            if (!mediaRecorder.value) {
                return
            }

            isRecording.value = true
            audioChunks.value = []
            mediaRecorder.value.start()

        } catch (error) {
            console.error('Permission denied or other error:', error)
            isRecording.value = false
            reset()
        }

    } else {
        if (mediaRecorder.value) {
            mediaRecorder.value.stop()
        }

        stop()
        isRecording.value = false
        isPaused.value = false
    }
}

const togglePause = () => {
    if (isPaused.value) {
        mediaRecorder.value.resume()
    } else {
        mediaRecorder.value.pause()
    }
    isPaused.value = !isPaused.value
}

const reset = () => {
    isCanceling.value = true;
    isRecording.value = false
    isPaused.value = false
    audioChunks.value = [];

    if (mediaRecorder.value) {
        mediaRecorder.value.stop()
    }

    stop()

    if (url.value) {
        window.URL.revokeObjectURL(url.value)
        url.value = ''
    }

    emits('update:recording', asFile(null));
}

const del = () => {
    if (url.value) {
        window.URL.revokeObjectURL(url.value)
        url.value = ''
    }

    emits('update:recording', asFile(null));
}

const recordingClasses = computed(() => {
    return {
        'text-destructive': isRecording.value,
        'animate-pulse': isRecording.value && !isPaused.value,
    }
})

const asFile = (blob) => {
    if (!blob) {
        return null
    }

    return new File([blob], `${uuidv4()}.wav`, { type: 'audio/wav' })
}

onUnmounted(() => {
    if (url.value) {
        window.URL.revokeObjectURL(url.value)
    }
})

</script>

<template>
    <div class="flex flex-col gap-1">
        <Transition mode="out-in">
            <div v-if="url && !isRecording" class="flex flex-row items-center justify-center">
                <audio :src="url" controls></audio>
            </div>
        </Transition>
        <div class="flex flex-row items-center justify-center gap-1">
            <Tooltip>
                <TooltipTrigger>
                    <Button @click="toggleRecording" variant="outline" size="icon" :class="recordingClasses" :disabled="props.readonly ? true : false">
                        <Icon :icon="micIcon" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {{ isRecording ? t('common.stop') : t('common.start') }}
                </TooltipContent>
            </Tooltip>
            <Transition mode="out-in">
                <span v-if="isRecording">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button size="icon" variant="secondary" @click="togglePause">
                                <Icon :icon="pauseIcon" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ isPaused ? t('common.resume') : t('common.pause') }}
                        </TooltipContent>
                    </Tooltip>
                </span>
            </Transition>
            <Transition mode="out-in">
                <span v-if="isRecording">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button variant="secondary" size="icon" @click="reset">
                                <Icon :icon="icons.trash" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.cancel') }}
                        </TooltipContent>
                    </Tooltip>
                </span>
            </Transition>
            <Transition mode="out-in">
                <div v-if="isGranted && microphones.length > 1">
                    <div class="flex flex-row items-center gap-1">
                        <Switch id="show-mode" :checked="showMicrophoneList" @update:checked="() => showMicrophoneList = !showMicrophoneList" />
                        <Label for="show-mode" class="text-sm text-muted-foreground hover:cursor-pointer">{{ t('common.changeMic') }}</Label>
                    </div>
                </div>
            </Transition>
            <Transition mode="out-in">
                <div v-if="isGranted && showMicrophoneList && !isRecording && microphones.length > 1">
                    <Dropdown :modelValue="selectedMicrophone" :items="microphones.map(x => ({ label: x.label, value: x.deviceId }))" @update:modelValue="v => selectedMicrophone = v" />
                </div>
            </Transition>
            <Transition mode="out-in">
                <span v-if="url && !isRecording">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button variant="secondary" size="icon" @click="del">
                                <Icon :icon="icons.trash" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.delete') }}
                        </TooltipContent>
                    </Tooltip>
                </span>
            </Transition>
        </div>
        <Transition mode="out-in">
            <div v-if="!props.readonly" class="flex flex-col items-center justify-center text-sm text-muted-foreground">
                <Transition mode="out-in">
                    <span v-if="!isGranted">{{ t('common.noMicPermission') }}</span>
                    <span v-else-if="url">{{ t('common.done') }}</span>
                    <span v-else-if="isGranted && !isRecording && !isPaused">{{ t('common.ready') }}</span>
                    <span v-else-if="isGranted && isRecording && isPaused">{{ t('common.paused') }}</span>
                    <span v-else-if="isGranted && isRecording" :class="recordingClasses">{{ t('common.recording') }}</span>
                </Transition>
            </div>
        </Transition>
    </div>

</template>