const CamelCasePattern = new RegExp("^([a-z]+)([A-Z][a-z]+)");
const PascalCasePattern = new RegExp("^([A-Z])([a-z]+)");

export function pascalCaseToCamelCase(propname) {
    if (PascalCasePattern.test(propname)) {
        return propname.charAt(0).toLowerCase() + propname.slice(1);
    }
    else {
        return propname;
    }
}

export function camelCaseToSnakeCase(propname) {
    let m1;
    while ((m1 = CamelCasePattern.exec(propname))) {
        propname = m1[1] + "_" + m1[2].charAt(0).toLowerCase() + m1[2].slice(1);
    }
    return propname;
}

export function camelCaseToPascalCase(camelCase) {
    return camelCase
        .replace(/([A-Z])/g, (match) => `${match}`)
        .replace(/^./, (match) => match.toUpperCase())
        .trim();
}

export function convertPropertyNames(obj, converterFn) {
    let r,
        value,
        t = Object.prototype.toString.apply(obj);

    if (t === "[object Object]") {
        r = {};

        for (var propname in obj) {
            value = obj[propname];
            r[converterFn(propname)] = convertPropertyNames(value, converterFn);
        }
        return r;
    } else if (t === "[object Array]") {
        r = [];

        for (var i = 0, L = obj.length; i < L; ++i) {
            value = obj[i];
            r[i] = convertPropertyNames(value, converterFn);
        }
        return r;
    }
    
    return obj;
}