<script setup>

import { ref, watchEffect, computed, onMounted, onUnmounted } from 'vue'
import { usePermission } from '@vueuse/core'
import { useUserMedia } from '@vueuse/core'
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import { icons } from '@/utils/icons';

const emits = defineEmits(['update:recording'])

const { t } = useI18n();

const selectedCamera = ref('')
const currentCamera = computed(() => selectedCamera.value)

const props = defineProps({
    readonly: {
        type: Boolean,
        default: false
    }
})

const { stream, start, stop } = useUserMedia({
    constraints: {
        video: { deviceId: currentCamera },
        audio: true
    },
})

const cameraAccess = usePermission('camera')
const microphoneAccess = usePermission('microphone')
const mediaRecorder = ref(null)
const videoChunks = ref([])
const isRecording = ref(false)
const isPaused = ref(false)
const showCameraList = ref(false)
const cameras = ref([])
const camIcon = computed(() => isRecording.value ? icons.stop : icons.camera)
const pauseIcon = computed(() => isPaused.value ? icons.camera : icons.pause)
const isGranted = computed(() => cameraAccess.value === 'granted' && microphoneAccess.value === 'granted')
const isCanceling = ref(false)
const url = ref('')
const videoRef = ref(null)

const getCameras = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices()
    cameras.value = devices.filter(device => device.kind === 'videoinput')

    if (cameras.value.length > 0 && !selectedCamera.value) {
        selectedCamera.value = cameras.value[0].deviceId
    }
}

watchEffect(() => {
    if (stream.value && videoRef.value) {
        videoRef.value.srcObject = stream.value
    }

    if (stream.value && !mediaRecorder.value) {
        mediaRecorder.value = new MediaRecorder(stream.value)

        mediaRecorder.value.ondataavailable = (event) => {
            videoChunks.value.push(event.data)
        }

        mediaRecorder.value.onstop = () => {
            if (videoChunks.value.length === 0) {
                return;
            }

            if (!isCanceling.value) {
                const blob = new Blob(videoChunks.value, { type: 'video/webm' })
                url.value = window.URL.createObjectURL(blob);
                emits('update:recording', asFile(blob))
            } else {
                isCanceling.value = false
            }

            videoChunks.value = []
            mediaRecorder.value = null
        }
    }
})

const toggleRecording = async () => {
    if (!isRecording.value) {

        try {
            await getCameras()
            await start()

            if (!mediaRecorder.value) {
                mediaRecorder.value = new MediaRecorder(stream.value)
                mediaRecorder.value.ondataavailable = (event) => {
                    videoChunks.value.push(event.data)
                }
                mediaRecorder.value.onstop = () => {
                    if (videoChunks.value.length === 0) {
                        return;
                    }

                    if (!isCanceling.value) {
                        const blob = new Blob(videoChunks.value, { type: 'video/webm' })
                        url.value = window.URL.createObjectURL(blob);
                        emits('update:recording', asFile(blob))
                    } else {
                        isCanceling.value = false
                    }

                    videoChunks.value = []
                    mediaRecorder.value = null
                }
            }

            isRecording.value = true
            videoChunks.value = []
            mediaRecorder.value.start()

        } catch (error) {
            console.error('Permission denied or other error:', error)
            isRecording.value = false
            reset()
        }

    } else {
        if (mediaRecorder.value) {
            mediaRecorder.value.stop()
        }

        stop()
        isRecording.value = false
        isPaused.value = false

        const finalBlob = new Blob(videoChunks.value, { type: 'video/webm' })
        url.value = window.URL.createObjectURL(finalBlob)
        emits('update:recording', asFile(finalBlob))
    }
}

const togglePause = () => {
    if (!mediaRecorder.value) return;

    if (mediaRecorder.value.state === 'recording') {
        mediaRecorder.value.pause();
        isPaused.value = true;
    } else if (mediaRecorder.value.state === 'paused') {
        mediaRecorder.value.resume();
        isPaused.value = false;
    }
}

const reset = () => {
    isCanceling.value = true;
    isRecording.value = false
    isPaused.value = false
    videoChunks.value = [];

    if (mediaRecorder.value) {
        mediaRecorder.value.stop()
    }

    stop()

    if (url.value) {
        window.URL.revokeObjectURL(url.value)
        url.value = ''
    }

    emits('update:recording', asFile(null));
}

const del = () => {
    if (url.value) {
        window.URL.revokeObjectURL(url.value)
        url.value = ''
    }

    emits('update:recording', asFile(null));
}

const recordingClasses = computed(() => {
    return {
        'text-destructive': isRecording.value,
        'animate-pulse': isRecording.value && !isPaused.value,
    }
})

const asFile = (blob) => {
    if (!blob) {
        return null
    }

    return new File([blob], `${uuidv4()}.webm`, { type: 'video/webm' })
}

onMounted(() => {
    if (videoRef.value && stream.value) {
        videoRef.value.srcObject = stream.value
    }
})

onUnmounted(() => {
    if (videoRef.value) {
        videoRef.value.srcObject = null
    }
    if (url.value) {
        window.URL.revokeObjectURL(url.value)
    }
})
</script>

<template>
    <div class="flex flex-col gap-1">
        <Transition mode="out-in">
            <div v-if="isRecording" class="flex flex-row items-center justify-center">
                <video ref="videoRef" class="w-full h-60" autoplay playsinline muted></video>
            </div>
            <div v-else-if="!isRecording && url" class="flex flex-row justify-center items-center">
                <video :src="url" controls></video>
            </div>
        </Transition>
        <div class="flex flex-row items-center justify-center gap-1">
            <Tooltip>
                <TooltipTrigger>
                    <Button @click="toggleRecording" variant="outline" size="icon" :class="recordingClasses" :disabled="props.readonly ? true : false">
                        <Icon :icon="camIcon" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {{ isRecording ? t('common.stop') : t('common.start') }}
                </TooltipContent>
            </Tooltip>
            <Transition mode="out-in">
                <span v-if="isRecording">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button size="icon" variant="secondary" @click="togglePause">
                                <Icon :icon="pauseIcon" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ isPaused ? t('common.resume') : t('common.pause') }}
                        </TooltipContent>
                    </Tooltip>
                </span>
            </Transition>
            <Transition mode="out-in">
                <span v-if="isRecording">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button variant="secondary" size="icon" @click="reset">
                                <Icon :icon="icons.trash" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.cancel') }}
                        </TooltipContent>
                    </Tooltip>
                </span>
            </Transition>
            <Transition mode="out-in">
                <div v-if="isGranted && cameras.length > 1">
                    <div class="flex flex-row items-center gap-1">
                        <Switch id="show-mode" :checked="showCameraList" @update:checked="() => showCameraList = !showCameraList" />
                        <Label for="show-mode" class="text-sm text-muted-foreground hover:cursor-pointer">{{ t('common.changeCam') }}</Label>
                    </div>
                </div>
            </Transition>
            <Transition mode="out-in">
                <div v-if="isGranted && showCameraList && !isRecording && cameras.length > 1">
                    <Dropdown :modelValue="selectedCamera" :items="cameras.map(x => ({ label: x.label, value: x.deviceId }))" @update:modelValue="v => selectedCamera = v" />
                </div>
            </Transition>
            <Transition mode="out-in">
                <span v-if="url && !isRecording">
                    <Tooltip>
                        <TooltipTrigger>
                            <Button variant="secondary" size="icon" @click="del">
                                <Icon :icon="icons.trash" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.delete') }}
                        </TooltipContent>
                    </Tooltip>
                </span>
            </Transition>
        </div>
        <Transition mode="out-in">
            <div v-if="!props.readonly" class="flex flex-col items-center justify-center text-sm text-muted-foreground">
                <Transition mode="out-in">
                    <span v-if="!isGranted">{{ t('common.noCamPermission') }}</span>
                    <span v-else-if="url && !isRecording">{{ t('common.done') }}</span>
                    <span v-else-if="isGranted && !isRecording && !isPaused">{{ t('common.ready') }}</span>
                    <span v-else-if="isGranted && isRecording && isPaused">{{ t('common.paused') }}</span>
                    <span v-else-if="isGranted && isRecording" :class="recordingClasses">{{ t('common.recording') }}</span>
                </Transition>
            </div>
        </Transition>
    </div>
</template>