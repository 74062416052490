import { getCurrentSubscription, getDecodedToken, removeToken } from '@/utils/auth'

export function buildNavData(navRoute, currentRoute, partial = false) {
    if (!navRoute) {
        return {
            variant: 'ghost',
            current: false,
            url: '#'
        };
    }

    const lower = navRoute.path.toLowerCase();
    const path = currentRoute.path.toLowerCase();

    const { access: navAccess, role: navRole } = navRoute.meta;
    const { access: tokenAccess, role: tokenRole } = getDecodedToken();
    const userAccess = tokenAccess.split(',').map(a => a.trim());

    const hasAccess = navAccess.some(a => userAccess.includes(a));
    const hasRole = navRole.some(r => tokenRole.includes(r));

    let isIt;
    if (partial) {
        isIt = path.split('/')[1].startsWith(lower.split('/')[1]);
    } else {
        isIt = navRoute.name === currentRoute.name;
    }

    const url = lower.startsWith('/') ? `#${lower}` : `#/${lower}`;

    return {
        variant: isIt ? 'default' : 'ghost',
        current: isIt,
        url: url,
        hasAccess: hasAccess,
        hasRole: hasRole,
        name: navRoute.name,
    };
};

export function isCurrent(route, link) {
    if (link.url === route.path) {
        return true;
    }

    if (link.current) {
        return true;
    }

    if (link.children && link.children.length > 0) {
        return link.children.some(x => x.current);
    }

    return false;
}

export function logout(router) {
    removeToken();
    router.push({ name: 'login' });
}

export function navigateHome(router) {
    const sub = getCurrentSubscription();
    if (!sub) {
        throw new Error('Subscription not found');
    }

    if (sub.isBuyer) {
        router.push({ name: 'buyer-home' });
    } else if (sub.isVendor) {
        router.push({ name: 'vendor-home' });
    } else {
        throw new Error('Invalid subscription entity');
    }
}

