import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
}

export const canPrev = (index) => {
  return index > 0;
}

export const canNext = (index, array) => {
  if (!array) {
      return false;
  }

  return index < array.length - 1;
}

export const moveBack = (index, array) => {
  if (!array) {
      return index;
  }

  if (canPrev(index, array)) {
      index = index - 1;
  }

  return index;
}

export const moveNext = (index, array) => {
  if (!array) {
      return index;
  }

  if (canNext(index, array)) {
      index = index + 1;
  }

  return index;
}

export const getVendorPackageStatusColor = (status) => {
  switch (status) {
      case 0:
          return 'bg-yellow-100 text-yellow-800';
      case 1:
          return 'bg-blue-100 text-blue-800';
      case 2:
          return 'bg-green-100 text-green-800';
      case 3:
          return 'bg-purple-100 text-purple-800';
      case 4:
          return 'bg-green-100 text-green-800';
      case 5:
          return 'bg-red-100 text-red-800';
      default:
          return 'bg-gray-100 text-gray-800';
  }
};

export const getVendorComponentStatusColor = (status) => {
  switch (status) {
      case 0:
          return 'bg-yellow-100 text-yellow-800';
      case 1:
          return 'bg-green-100 text-green-800';
      case 2:
          return 'bg-red-100 text-red-800';
      default:
          return 'bg-gray-100 text-gray-800';
  }
};

export const getCommentStatusColor = (status) => {
  switch (status) {
      case 0:
          return 'bg-yellow-100 text-yellow-800';
      case 1:
          return 'bg-blue-100 text-blue-800';
      case 3:
          return 'bg-green-100 text-green-800';
      default:
          return '';
  }
}

export const getTimeAgoStatusColor = (date) => {
  const now = new Date();
  const then = new Date(date);

  if (then < now) {
      return 'bg-red-100 text-red-800';
  }

  if (then > now) {
      return 'bg-green-100 text-green-800';
  }

  return 'bg-yellow-100 text-yellow-800';
}

export const handleFileChange = (e, item) => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null;
    item.file = file;
}

export function initials(name) {
    return name
      .split(' ')
      .map((part) => part[0].toUpperCase())
      .join('');
  }