<script setup>

import { useGlobalState } from '@/views/_shared/stores/GlobalState';
const store = useGlobalState();

const addBoundToUrl = (url) => {
    if(url.startsWith('#')) {
        return url;
    }

    return '/#' + url;
};

</script>

<template>
    <Transition mode="out-in">
        <div v-if="store.breadcrumb.length > 0">
            <Breadcrumb class="hidden md:flex">
                <BreadcrumbList>
                    <template v-for="(item, index) in store.breadcrumb" :key="index">
                        <BreadcrumbItem>
                            <BreadcrumbLink v-if="item.link" as-child>
                                <a :href="addBoundToUrl(item.link)" class="flex flex-row items-center font-semibold">
                                    <Icon v-if="item.icon" :icon="item.icon" />
                                    <span :class="item.icon ? 'ms-2' : ''">
                                        {{ item.title }}
                                    </span>
                                </a>
                            </BreadcrumbLink>
                            <BreadcrumbPage v-else class="flex flex-row items-center font-semibold">
                                <Icon v-if="item.icon" :icon="item.icon" />
                                <span :class="item.icon ? 'ms-2' : ''">
                                    {{ item.title }}
                                </span>
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator v-if="index < store.breadcrumb.length - 1" />
                    </template>
                </BreadcrumbList>
            </Breadcrumb>
        </div>
    </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>