<script setup>
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n'

import { post } from '@/utils/http';
import { setToken, setReturnUrl, isUserAuthenticated } from '@/utils/auth';

import { icons } from '@/utils/icons';

import { navigateHome } from "@/utils/nav";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

onMounted(() => {
    if (isUserAuthenticated()) {
        navigateHome(router);
        return;
    }

    post('auth/enter', {
        magicHash: route.params.hash,
        magicToken: route.params.token
    }).then((response) => {
        if(response.status !== 200) {
            //TODO(T): MESSAGE THE USER
            return;
        }

        setToken(response.data.jwt);
        setReturnUrl(response.data.returnUrl);
        
        navigateHome(router);
    }).catch((error) => {
        console.error(error);

        router.push({ name: 'login' });
    })
});

</script>

<template>
    <ScrollArea class="h-full w-full">
        <div class="flex flex-row items-center justify-center h-svh">
            <Card class="flex flex-col justify-center space-y-6 sm:w-[350px] p-4">
                <div class="flex flex-col space-y-2 text-center items-center">
                    <figure class="mb-3">
                        <AlinorLogo />
                    </figure>
                    {{ t('views.auth.enter.welcome') }}
                </div>
                <Alert class="w-full text-start">
                    <Icon :icon="icons.loaderCircle" class="mr-2 h-4 w-4 animate-spin" />
                    <AlertTitle>{{ t('views.auth.enter.hangTight') }}</AlertTitle>
                    <AlertDescription>
                        {{ t('views.auth.enter.loggingIn') }}
                    </AlertDescription>
                </Alert>
            </Card>
        </div>
    </ScrollArea>

</template>
