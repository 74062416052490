<script setup>

import { icons } from '@/utils/icons';

const props = defineProps({
    text: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    processing: {
        type: Boolean,
        default: false
    },
    icon: {
        type: String,
        default: ''
    },
    variant: {
        type: String,
        default: 'default'
    }
});

const emits = defineEmits(['click']);

</script>

<template>
    <Button @click="emits('click')" :disabled="props.disabled" :variant="props.variant">
        <Transition mode="out-in">
            <Icon :icon="icons.loaderCircle" v-if="processing" class="h-4 w-4 animate-spin" />
            <span v-else class="flex flex-row items-center gap-2">
                {{ text }}
                <Icon v-if="icon" :icon="icon" class="h-4 w-4" />
            </span>
        </Transition>
    </Button>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>