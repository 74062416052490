<script setup>

const props = defineProps({
    text: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    bgColor: {
        type: String,
        default: 'bg-primary'
    },
    textColor: {
        type: String,
        default: 'text-white'
    }
});

</script>

<template>
    <Transition mode="out-in">
        <div v-if="!!props.icon || !!props.text" class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900" :class="[props.bgColor, props.textColor]">
            <Transition mode="out-in">
                <span v-if="!!props.text">{{ props.text }}</span>
            </Transition>
            <Transition mode="out-in">
                <Icon v-if="props.icon" :icon="props.icon" class="h-3 w-3" />
            </Transition>
        </div>
    </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>