<script setup>

import { onBeforeEnter, onEnter, onLeave } from '@/utils/animations';

const props = defineProps({
    items: {
        type: Array,
        default: []
    },
    isPosting: {
        type: Boolean,
        default: false
    },
    hasRemove: {
        type: Boolean,
        default: true
    }
});

const emits = defineEmits(['remove']);

</script>

<template>
    <Transition mode="out-in">
        <div v-if="items" ref="el" class="space-y-2.5 w-full">
            <TransitionGroup name="list" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
                <div v-for="(item, index) in props.items" :key="index" class="flex flex-row items-top gap-2">
                    <slot name="item" v-bind="{ item, index }" />
                    <Transition mode="out-in">
                        <ConfirmClose v-if="hasRemove" :key="index" :isDisabled="props.isPosting" @close="emits('remove', { item: item, index: index })" />
                    </Transition>
                </div>
            </TransitionGroup>
        </div>
    </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>