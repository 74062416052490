<script setup>
import { renderMarkdown } from '@/utils/markdown';

const props = defineProps({
  content: {
    type: String,
    default: ""
  },
  class: {
    type: String,
    default: "p-3"
  }
});
</script>

<template>
  <div v-if="props.content" v-html="renderMarkdown(content)" :class="[props.class]" />
</template>