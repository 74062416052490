const prefix = 'lucide:';

const i = (icon) => {
    return `${prefix}${icon}`;
}

export const icons = {
    ai: i('sparkles'),
    agent: i('bot'),
    extract: i('pickaxe'),
    link: i('link'),
    document: i('file-text'),
    download: i('download'),
    package: i('package'),
    content: i('scan-text'),
    check: i('check'),
    xxx: i('x'),
    attachments: i('paperclip'),
    plus: i('plus'),
    minus: i('minus'),
    command: i('command'),
    settings: i('settings'),
    infinity: i('infinity'),
    templates: i('notepad-text-dashed'),
    logout: i('log-out'),
    vendors: i('handshake'),
    user: i('user'),
    admin: i('crown'),
    users: i('users'),
    requirements: i('clipboard-list'),
    evaluationCriteria: i('badge-check'),
    addUser: i('user-plus'),
    input: i('text-cursor-input'),
    slidesH: i('sliders-horizontal'),
    slidesV: i('sliders-vertical'),
    questionFile: i('file-question'),
    event: i('zap'),
    action: i('brain-circuit'),
    data: i('database'),
    weight: i('weight'),
    work: i('briefcase'),
    status: i('circle'),
    view: i('eye'),
    viewOff: i('eye-off'),
    copy: i('copy'),
    ellipsis: i('ellipsis'),
    edit: i('square-pen'),
    tasks: i('list-todo'),
    more: i('ellipsis'),
    trash: i('trash'),
    loaderCircle: i('loader-circle'),
    loader: i('loader'),
    left: i('chevron-left'),
    right: i('chevron-right'),
    search: i('search'),
    rocket: i('rocket'),
    redo: i('redo-2'),
    undo: i('undo-2'),
    switch: i('arrow-left-right'),
    pen: i('pencil'),
    reject: i('ban'),
    question: i('message-circle-question'),
    deviation: i('replace'),
    date: i('calendar-clock'),
    info: i('info'),
    project: i('boxes'),
    number: i('hash'),
    comment: i('messages-square'),
    award: i('award'),
    grip: i('grip-horizontal'),
    mic: i('mic'),
    camera: i('webcam'),
    stop: i('circle-stop'),
    pause: i('pause'),
    screen: i('screen-share'),
    automations: i('brain-circuit'),
    zap: i('zap'),
    cpu: i('cpu'),
    dashboard: i('house'),
};



