
export const en = {
    common: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        contactPerson: 'Contact person',
        contactEmail: 'Contact email',
        cancel: 'Cancel',
        noData: 'Nothing here yet',
        noDataDescription: 'There is no data to display here yet.',
        noResultsFound: 'No results found',
        create: 'Create',
        save: 'Save',
        submit: 'Submit',
        send: 'Send',
        createNew: 'Create new',
        actions: 'Actions',
        edit: 'Edit',
        delete: 'Delete',
        manage: 'Manage',
        next: 'Next',
        previous: 'Previous',
        limit: 'Limit',
        name: 'Name',
        description: 'Description',
        add: 'Add',
        data: 'Data',
        generate: 'Generate',
        link: 'Link',
        type: 'Type',
        details: 'Details',
        description: 'Description',
        project: 'Project',
        requirement: 'Requirement',
        areYouSure: 'Are you sure?',
        replace: 'Replace',
        replaceContext: 'Replace context',
        replaceOrginal: 'Replace original',
        thisActionCannotBeUndone: 'This action cannot be undone.',
        step: 'Step {step} out of {total}',
        collectionXofY: 'Collection {step} out of {total}',
        noResultsFound: 'No results found',
        filterX: 'Filter {x}',
        filter: 'Filter',
        search: 'Search',
        selectX: 'Select {x}',
        select: 'Select',
        writeSomething: 'Write something',
        fixTheGrammer: 'Fix the grammar',
        instructions: 'Instructions',
        responseTemplate: 'Response template',
        content: 'Content',
        how: 'How?',
        why: 'Why?',
        references: 'References',
        referencesDescription: 'Add documents, websites, and other references to support the project.',
        documents: 'Documents',
        document: 'Document',
        documentsDescription: 'Generate project documents.',
        assignToUser: 'Assign to person',
        website: 'Website',
        person: 'Person',
        people: 'People',
        tags: 'Tags',
        information: 'Information',
        noAnswerFound: 'No answer found',
        chatWithInformationInThisProject: 'Chat with information in this project',
        xOutOfYSelected: '{x} out of {y} selected',
        xOutOfY: '{x} out of {y}',
        download: 'Download',
        pdf: 'PDF',
        docx: 'DOCX',
        write: 'Write',
        rewrite: 'Rewrite',
        executeYourPrompt: 'Execute your prompt',
        adminTitle: 'Is admin?',
        admin: 'Admin',
        adminDescription: 'Admins have access to settings.',
        packages: 'RFXs',
        package: 'RFx',
        packagesDescription: 'Create an RFx for the project.',
        zipArchive: 'ZIP archive',
        content: 'Content',
        tasks: 'Tasks',
        tasksDescription: 'Comment and assign tasks to members.',
        task: 'Task',
        visablity: 'Visablity',
        members: 'Members',
        settings: 'Settings',
        history: 'History',
        changes: 'Changes',
        elements: 'Elements',
        element: 'Element',
        comments: 'Comments',
        comment: 'Comment',
        analyze: 'Analyze',
        refFiles: 'Reference files',
        refFilesDescription: 'Add reference files to be analized',
        addAnalysisInstructions: 'Add instructions for the analysis',
        downloadHasStarted: 'Download has started',
        ok: 'Ok',
        close: 'Close',
        showTasks: 'Show tasks',
        sections: 'Sections',
        sectionsDescription: 'Manage sections of the project.',
        template: 'Template',
        value: 'Value',
        references: 'References',
        reference: 'Reference',
        extractSections: 'Extract sections?',
        extractSectionsDescription: 'Extract sections from a source',
        extractContent: 'Extract content?',
        extractContentDescription: 'Extract content from the source',
        projects: 'Projects',
        project: 'Project',
        generateInstructions: 'Generate instructions',
        extractInstructions: 'Extract instructions',
        readInstructions: 'Read instructions',
        agents: 'Agents',
        agent: 'Agent',
        templates: 'Templates',
        template: 'Template',
        basicInformation: 'Basic information',
        selectSections: 'Select sections',
        changeFile: 'Change file',
        view: 'View',
        source: 'Source',
        extraction: 'Extraction',
        extract: 'Extract',
        blank: '- Blank -',
        newDocument: 'New document',
        latestDocuments: 'Latest documents',
        latestPackages: 'Latest RFXs',
        version: 'Version {x}',
        versions: 'Versions',
        showHistory: 'History',
        addSection: 'Add section',
        marks: 'Marks',
        focusMode: 'Focus mode',
        listMode: 'List mode',
        section: 'Section',
        xOfY: '{x} / {y}',
        targetDate: 'Target date',
        assignTo: 'Assign to',
        new: 'New',
        typeACommandOrSearch: 'Search RFx templates',
        yourDocuments: 'Your documents',
        yourProjects: 'Your projects',
        tasksThatAreAssignedToYou: 'Tasks that are assigned to you',
        tasksThatYouHaveCreated: 'Tasks that you have created',
        work: 'Work',
        requirements: 'Requirements',
        requirement: 'Requirement',
        sectionOf: 'Section {x} of {y}',
        requirementOf: 'Requirement {x} of {y}',
        what: 'What?',
        next: 'Next',
        review: 'Review',
        vendors: 'Suppliers',
        businessId: 'Business-ID',
        contacts: 'Contacts',
        contact: 'Contact',
        sources: 'Sources',
        noAnswerFound: 'No answer found',
        askAQuestion: 'Ask a question about documents or projects',
        splitScreen: 'Split screen',
        minimize: 'Minimize',
        pickTemplate: ' Pick a template',
        supportingReferences: 'Supporting references',
        specifications: 'Specifications',
        addRequirements: 'Add Requirement',
        createPackage: 'Create RFXs',
        addSource: 'Add source',
        priority: 'Priority',
        scoreWeight: 'Score weight',
        score: 'Score',
        owner: 'Owner',
        communication: 'Communication',
        attachments: 'Attachments',
        addAttachment: 'Add attachment',
        extractRequirements: 'Extract requirements',
        extractRequirementsDescription: 'Extract requirements from a source',
        fillInformation: 'Fill information',
        addContact: 'Add contact',
        collection: 'Data collection',
        requirementsAndCritera: 'Requirements & Evaluation Criteria',
        supportingMaterials: 'Supporting materials',
        doYouWantToCollectInformationFromSender: 'When created, do you want to collect information from the sender?',
        doYouWantToCollectInformationFromRReceiver: 'When sent, do you want to collect information from the receiver?',
        doYouWantToCollectInformationOnOfferSubmission: 'When an offer is submitted, do you want to collect information?',
        doYouWantToIncludeRequirements: 'Do you want to include standard requirements?',
        doYouWantToIncludeEvaluationCriteria: 'Do you want to include evaluation criteria?',
        doYouWantToIncludeDocuments: 'Do you want to include documents?',
        doYouWantToIncludeSupportingReferences: 'Do you want to include references?',
        thisIsRepeated: 'This is repeated every time.',
        theseAreIncludedEveryTime: 'These are included every time.',
        withAi: 'With AI',
        WithoutAi: 'Without AI',
        extractInformation: 'Extract information',
        extractInformationDescription: 'Extract information from a source',
        whatIsTheType: 'What is the type?',
        howManyDoYouWant: 'How many do you want?',
        automations: 'Automations',
        event: 'Event',
        action: 'Action',
        when: 'When',
        do: 'Do',
        group: 'Group',
        function: 'Function',
        collection: 'Collection',
        many: 'Many',
        date: 'Date',
        number: 'Number',
        filter: 'Filter',
        vendor: 'Supplier',
        status: 'Status',
        reset: 'Reset',
        message: 'Message',
        timeline: 'Latest activity',
        created: 'Created',
        target: 'Target',
        invite: 'Add',
        inviteVendor: 'Add supplier',
        editIndvidualMessage: 'Edit individual message',
        noDescription: 'No description',
        noAttachments: 'No attachments',
        noTasks: 'No tasks',
        noValues: 'No values',
        noValue: 'No value',
        copy: 'Copy',
        or: 'or',
        values: 'Values',
        addAnother: 'Add another',
        manageSupportingMaterials: 'Manage supporting materials',
        dueInDaysFromTrigger: 'Due in days from trigger',
        addComment: 'Add comment',
        changeStatus: 'Change status',
        confirmEmail: 'Confirm email',
        emailsMustMatch: 'Emails must match',
        createFromScratch: 'Create from scratch',
        today: 'Today',
        switch: 'Switch',
        buyer: 'Buyer',
        reject: 'Reject',
        accept: 'Accept',
        exactly: 'Exactly',
        deviation: 'Deviation',
        undo: 'Undo',
        confirm: 'Confirm',
        requirements: 'Requirements',
        requirement: 'Requirement',
        duplicate: 'Duplicate',
        inputFromVendor: 'Input from supplier',
        content: 'Content',
        noVendorsInvited: 'No suppliers added',
        informationProvidedToYou: 'Information provided to you',
        informationYouNeedToProvide: 'Information you need to provide',
        deviationTitle: 'Mark as a deviation',
        deviationDescription: 'Indicate a deviation from the requirements',
        targetDateTitle: 'Indicate a target date',
        targetDateDescription: 'Mark a deadline for the comment',
        viewPreviousComments: 'View Previous Comments',
        hideComments: 'Hide Comments',
        deviation: 'Deviation',
        updateSpecificVendorsTitle: 'Update specific supplier',
        updateSpecificVendorsDescription: 'Update specific suppliers with new information',
        assignToUserTitle: 'Assign to person',
        assignToUserDescription: 'Assign the task to a specific person',
        templateExample: 'Example: RFx for Service or Part',
        requirementExample: 'Example: NDA, Scope of work, Line Item, Technical requirement',
        requirementCountTitle: 'How many times does this appear?',
        requirementCountDescription: 'Indicate how many times this requirement should appear per template',
        requirementBuyerInputTitle: 'Do you send information to the supplier?',
        requirementBuyerInputDescription: 'Information you need to provide to the supplier',
        requirementVendorInputTitle: 'Do you receive information from the supplier?',
        requirementVendorInputDescription: 'Information the supplier needs to provide to you',
        commentsAreEnabledByDefault: 'You and the supplier are able to add comments by default',
        addToExitingProject: 'Add to existing project',
        doesThisPackageBelongToAProject: 'Does this RFx belong to a project?',
        uniqueRef: 'Unique reference',
        addToProject: 'Add to project',
        inviteVendors: 'Add suppliers',
        whoWouldYouLikeToContact: 'Who would you like to contact?',
        communcation: 'Communication',
        activeProjects: 'Active projects',
        copied: 'Copied!',
        copiedToClipboard: 'Copied to clipboard',
        existing: 'Existing',
        resolve: 'Resolve',
        reason: 'Enter resolution here',
        inputs: 'Inputs',
        dragAndDropInputs: 'Drag and drop inputs here',
        setInputSettings: 'Set input settings',
        overviewMode: 'Overview mode',
        total: 'Total',
        packageSubmited: 'Information submited',
        packageSubmittedDescription: 'The information has been submitted and is waiting for review.',
        commentSubmitted: 'Comment submited',
        commentSubmittedDescription: 'The comment has been submitted and the buyer has been informed.',
        rejectionSubmitted: 'Rejection submited',
        rejectionSubmittedDescription: 'The rejection has been submitted and the buyer has been informed.',
        yes: 'Yes',
        no: 'No',
        evaulation: 'Evaluation',
        evaluationCriteria: 'Evaluation criteria',
        evaluationRound: 'Evaluation round',
        evaluationRounds: 'Evaluation rounds',
        evaluationRoundOfX: 'Round {x}',
        round: 'Round',
        criteria: 'Criteria',
        weight: 'Weight',
        awardPackageToVendor: 'Award package to supplier',
        awardRequirementsToVendor: 'Award requirements to supplier',
        award: 'Award',
        note: 'Note',
        uploadRefDescription: 'Upload reference for the AI to use',
        rfxAiDescription: 'Describe what should exist in this RFx',
        optional: 'Optional',
        useAI: 'Use AI',
        NA: 'N/A',
        remove: 'Remove',
        fillWithAi: 'Fill with AI',
        whatDataDoYouWantToCollect: 'What data do you want to collect?',
        changeMic: 'Change mic?',
        changeCam: 'Change cam?',
        pause: 'Pause',
        start: 'Start',
        stop: 'Stop',
        resume: 'Resume',
        noMicPermission: 'No mic permission',
        noCamPermission: 'No cam permission',
        noScreenPermission: 'No screen permission',
        noMicFound: 'No mic found',
        ready: 'Ready',
        recording: 'Recording',
        paused: 'Paused',
        done: 'Done',
        automations: 'Automations',
        on: 'On',
        do: 'Do',
        trigger: 'Trigger',
        action: 'Action',
        dashboard: 'Dashboard',
        myAccount: 'My Account',
        evaluation: 'Evaluation',
        evaluate: 'Evaluate',
        findInformationInsideThisProject: 'Find information inside this RFx',
        negotiation: 'Negotiation AI',
        beta: 'Beta',
        supplierPreview: 'Supplier preview',
        deviations: 'Deviations',
        noDeviations: 'No deviations',
    },
    settings: {
        settings: 'Settings',
        settingsDescription: 'Manage your organization settings.',
        templates: 'Templates',
        templatesDescription: 'Manage reusable requirements.',
        projects: 'Projects',
        packages: 'RFXs',
        documents: 'Documents',
        requirements: 'Requirements',
        evaluationCriteria: 'Evaluation Criteria',
        references: 'References',
        agents: 'Agents',
        vendors: 'Suppliers',
        users: 'Users',
        automations: 'Automations',
    },
    wizards: {
        createPackage: {
            basicInformation: 'Basic information',
            basicInformationDescription: 'Fill in the basic information for the RFx.',
            requirements: 'Requirements',
            requirementsDescription: 'Add requirements to the RFx.',
            vendors: 'Suppliers',
            vendorsDescription: 'Send the RFx to suppliers.',
        }
    },
    editor: {
        bold: 'Bold',
        italic: 'Italic',
        strike: 'Strike',
        code: 'Code',
        highlight: 'Highlight',
        h1: 'Header 1',
        h2: 'Header 2',
        paragraph: 'Paragraph',
        bulletList: 'Bullet list',
        orderedList: 'Ordered list',
        taskList: 'Task list',
        codeBlock: 'Code block',
        blockQuote: 'Block quote',
        horizontalRule: 'Horizontal rule',
        hardBreak: 'Hard break',
        clearFormatting: 'Clear formatting',
        undo: 'Undo',
        redo: 'Redo',
        words: '{words} Words ',
        characters: '{characters} Characters',
    },
    nav: {
        logout: 'Logout',
    },
    views: {
        auth: {
            enter: {
                loggingIn: 'You are being logged in...',
                hangTight: 'Hang tight!',
                welcome: 'Welcome'
            },
            unauthorized: {
                title: 'Unauthorized',
                description: 'You are not authorized to access this page.',
                goBack: 'Go back',
            },
            login: {
                magicLink: 'We sent you a magic link to your email',
                checkYourInbox: 'Check your inbox',
                signIn: 'Sign in',
                enterYourEmail: 'Enter your email below to sign in to your account',
            },
            register: {
                organization: 'Organization name',
                businessId: 'Business-ID',
                magicLink: 'We sent you a magic link to your email',
                checkYourInbox: 'Check your inbox',
                register: 'Register',
                start: 'Start',
                yourAccountWillbeValidatedByAlinor: 'Your account will be validated by Alinor',
            }
        },
    }
}
