import MarkdownIt from "markdown-it";

//TODO(T): DANGER XSS
const markdown = new MarkdownIt({
    html: true,
});

export const renderMarkdown = (text) => {
    if (!text) {
        return '';
    }

    return markdown.render(text);
} 