import axios from 'axios';
import { getToken, removeToken } from '@/utils/auth';

import { convertPropertyNames, camelCaseToPascalCase } from '@/utils/converters';

const ApiEndpoint = import.meta.env.VITE_BACKEND_API_ENDPOINT;

function createAxiosInstance(includeToken, timeout = 1000000) {

  const token = includeToken ? getToken() : null;
  const instance = axios.create({
    baseURL: ApiEndpoint,
    timeout: timeout,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });
  return instance;
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 || error.response.status === 403) {
    removeToken();
    window.location.reload();
  }
  return error;
});

export function get(url, includeToken = true, config = {}) {

  const instance = createAxiosInstance(includeToken);
  return instance.get(trim(url, '/'), config);

}

export function post(url, data = {}, includeToken = true, config = {}) {

  const instance = createAxiosInstance(includeToken);
  return instance.post(trim(url, '/'), data, config);

}

export function put(url, data = {}, includeToken = true, config = {}) {

  const instance = createAxiosInstance(includeToken);
  return instance.put(trim(url, '/'), data, config);

}

export function figurePostPutOut(url, data = {}, includeToken = true, config = {}) {
  if (data.id) {
    return put(url, data, includeToken, config);
  } else {
    return post(url, data, includeToken, config);
  }
}

export function figureFormOut(url, data = {}, includeToken = true, config = {}) {
  if (data.id) {
    return form(`${trim(url, '/')}/update`, data, includeToken, config);
  } else {
    return form(`${trim(url, '/')}/create`, data, includeToken, config);
  }
}

export function del(url, includeToken = true, config = {}) {

  const instance = createAxiosInstance(includeToken);
  return instance.delete(trim(url, '/'), config);

}

function appendFormData(formData, key, value) {
  if (value instanceof File) {
    formData.append(key, value);
  } else if (Array.isArray(value)) {

    value.forEach((val, i) => {
      appendFormData(formData, `${key}[${i}]`, val);
    });

  } else if (typeof value === 'object' && hasValue(value)) {
    for (let subKey in value) {
      if (hasValue(value[subKey])) {
        appendFormData(formData, `${key}.${subKey}`, value[subKey]);
      }
    }
  } else {
    if (hasValue(value)) {
      formData.append(key, value);
    }
  }
}

const hasValue = (value) => value !== null && value !== undefined && value !== NaN;

export function form(url, data = {}, includeToken = true, config = {}) {

  const converted = convertPropertyNames(data, camelCaseToPascalCase);

  const instance = createAxiosInstance(includeToken);
  const formData = new FormData();

  for (const key in converted) {
    if (converted.hasOwnProperty(key)) {
      appendFormData(formData, key, converted[key]);
    }
  }


  return instance.post(trim(url, '/'), formData, {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      window.dispatchEvent(new CustomEvent(UploadEvent, { detail: percentCompleted }));
    }
  });

}

function trim(str, ch) {
  var start = 0,
    end = str.length;

  while (start < end && str[start] === ch)
    ++start;

  while (end > start && str[end - 1] === ch)
    --end;

  return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export const UploadEvent = 'upload.update-event';