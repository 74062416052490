<script setup>

import { computed } from 'vue'

const MAX = 999999999999999;

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    style: {
        type: String,
        required: false,
    },
    currency: {
        type: String,
        required: false,
    },
    currencyDisplay: {
        type: String,
        required: false,
    },
    currencySign: {
        type: String,
        required: false,
    },
    min: {
        type: Number,
        required: false,
    },
    max: {
        type: Number,
        required: false,
        default: MAX,
    },
    modelValue: {
        type: Number,
        default: null,
    },
    step: {
        type: Number,
        required: false,
    },
    readonly: {
        type: Boolean,
        default: false
    }
});


const emits = defineEmits(['update:modelValue']);

const value = computed({
    get: () => {
        if (props.modelValue !== null && props.modelValue !== undefined) {
            return props.modelValue;
        }

        return undefined;
    },
    set: val => val,
})

const updateValue = (value) => {
    if (value === undefined) {
        value = null;
    }

    emits('update:modelValue', value);
}

</script>

<template>
    <div class="min-w-fit">
        <NumberField :modelValue="value" :min="props.min" :max="props.max ?? MAX" :format-options="{
            style: props.style,
            currency: props.currency,
            currencyDisplay: props.currencyDisplay,
            currencySign: props.currencySign,
            step: props.step,
            disabled: props.readonly ? true : false,
        }" @update:modelValue="v => updateValue(v)">
            <NumberFieldContent>
                <NumberFieldDecrement :disabled="props.readonly ? true : false" />
                <NumberFieldInput :placeholder="props.title" :readonly="props.readonly" />
                <NumberFieldIncrement :disabled="props.readonly ? true : false"  />
            </NumberFieldContent>
        </NumberField>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>