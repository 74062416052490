<script setup>

import { icons } from '@/utils/icons';

import { handleFileChange } from '@/lib/utils';

const props = defineProps({
    value: Object,
    element: Object,
    readonly: {
        type: Boolean,
        default: false
    }
});

</script>

<template>
    <Input v-if="element.type === 0" class="bg-white" type="text" :placeholder="element.name" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" />

    <Textarea v-else-if="element.type === 1" :modelValue="value.value" @update:modelValue="v => value.value = v" :readonly="props.readonly" class="w-full h-24" :placeholder="element.name"></Textarea>

    <Number v-else-if="element.type === 2" :title="element.name" :modelValue="parseFloat(value.value)" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

    <DatePicker v-else-if="element.type === 3" :title="element.name" :modelValue="value.value" @update:modelValue="v => value.value = v?.toString()" :readonly="props.readonly" />

    <div v-if="element.type === 4">
        <Input type="file" :placeholder="element.name" @change="e => handleFileChange(e, value)" @blur="e => handleFileChange(e, value)" @rest="e => handleFileChange(e, value)" :readonly="props.readonly" :disabled="props.readonly ? true : false" />
    </div>

    <div v-if="element.type === 5">
        <CheckCard :isChecked="value.value === '1'" @update:modelValue="v => value.value = (v ? '1' : '0')" :title="element.name" :checkIcon="icons.check" :readonly="props.readonly" />
    </div>

    <div v-if="element.type === 6">
        <VoiceRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
    </div>

    <div v-if="element.type === 7">
        <CameraRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
    </div>

    <div v-if="element.type === 8">
        <ScreenRecorder :readonly="props.readonly" @update:recording="v => value.file = v" />
    </div>

</template>


<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>