<script setup>

import { onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid';
import { onBeforeEnter, onEnter, onLeave } from '@/utils/animations';

import { icons } from '@/utils/icons';

const { t } = useI18n();

const props = defineProps({
    element: Object,
    readonly: {
        type: Boolean,
        default: false
    }
});

onMounted(() => {
    props.element.values = !props.element.values || props.element.values.length === 0 ? [{ uid: uuidv4(), value: null }] : props.element.values;

});

watch(() => props.element, (newVal, _) => {
    props.element.values = !props.element.values || props.element.values.length === 0 ? [{ uid: uuidv4(), value: null }] : props.element.values;
});

const isButtonDisabled = computed(() => props.readonly ? true : false);

</script>

<template>

    <TransitionGroup name="list" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
        <div v-for="(value, vIndex) in element.values" :key="vIndex" class="w-full my-1.5">
            <ValueInput :value="value" :element="element" :readonly="props.readonly" />
        </div>
    </TransitionGroup>
    <div v-if="element.inputCount === 1">
        <div class="flex flex-row items-center justify-between gap-2">
            <div class="py-2 text-center text-sm text-muted-foreground">
                {{ t('common.xOutOfY', { x: element.values.length, y: element.responseLimit }) }}
            </div>
            <div class="flex flex-row items-center gap-2">
                <Transition mode="out-in">
                    <Button :disabled="isButtonDisabled" v-if="element.values.length !== 0" @click="element.values.pop()" variant="outline" size="icon">
                        <Icon :icon="icons.minus" class="h-4 w-4" />
                    </Button>
                </Transition>
                <Transition mode="out-in">
                    <Button :disabled="isButtonDisabled" v-if="element.values.length < element.responseLimit" @click="element.values.push({ uid: uuidv4(), value: null })" variant="outline" size="icon">
                        <Icon :icon="icons.plus" class="h-4 w-4" />
                    </Button>
                </Transition>
            </div>

        </div>
    </div>
    <div v-else-if="element.inputCount === 2">
        <div class="flex flex-row items-center justify-between gap-2">
            <div class="py-2 text-center text-sm text-muted-foreground">
                {{ t('common.xOutOfY', { x: element.values.length, y: t('common.many') }) }}
            </div>
            <div class="flex flex-row items-center gap-2">
                <Transition mode="out-in">
                    <Button :disabled="isButtonDisabled" v-if="element.values.length !== 0" @click="element.values.pop()" variant="outline" size="icon">
                        <Icon :icon="icons.minus" class="h-4 w-4" />
                    </Button>
                </Transition>
                <Button :disabled="isButtonDisabled" @click="element.values.push({ uid: uuidv4(), value: null })" variant="outline" size="icon">
                    <Icon :icon="icons.plus" class="h-4 w-4" />
                </Button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>