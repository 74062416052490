<script setup>

import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n'

import { post } from '@/utils/http';

import { icons } from '@/utils/icons';

const { t } = useI18n();

const props = defineProps({
    data: {
        type: Object,
        default: {
            url: null,
            payload: null,
        },
    },
})

const emits = defineEmits(['delete']);

const isDeleting = ref(false);
const isOpen = ref(props.data?.isOpen ?? false);

watch(() => props.data, (value) => {
    isOpen.value = !!value && !!value.url && !!value.payload;
});

const del = async () => {
    if (!props.data.payload) {
        return;
    }

    if (!props.data.url) {
        return;
    }

    if (isDeleting.value) {
        return;
    }

    isDeleting.value = true;

    post(props.data.url, props.data.payload).then(() => {
        emits('delete');
    }).finally(() => {
        isDeleting.value = false;
    });
}

const changeOpen = (o) => {
    isOpen.value = o;
}
</script>

<template>
    <AlertDialog :open="isOpen" @update:open="changeOpen">
        <AlertDialogContent>
            <AlertDialogHeader>
                <AlertDialogTitle>{{ t('common.areYouSure') }}</AlertDialogTitle>
                <AlertDialogDescription>
                    {{ t('common.thisActionCannotBeUndone') }}
                </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
                <AlertDialogCancel :disabled="isDeleting">{{ t('common.cancel') }}</AlertDialogCancel>
                <AlertDialogAction :disabled="isDeleting" class="bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90" @click="del">
                    <Transition mode="out-in">
                        <Icon :icon="icons.loaderCircle" v-if="isDeleting" class="w-4 h-4" />
                        <span v-else>{{ t('common.delete') }}</span>
                    </Transition>
                </AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>