<script setup>

import { ref, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';

const { t } = useI18n();
const store = useGlobalState();

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    title: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    url: {
        type: String,
        default: ''
    },
    fun: {
        type: Function,
    },
    isValid: {
        type: Boolean,
        default: false
    },
    refershLookups: {
        type: Boolean,
        default: false
    }
});

const emits = defineEmits(['update:modelValue', 'close']);

const isPosting = ref(false);

const submit = async () => {
    if (!props.isValid) {
        return;
    }

    if (isPosting.value) {
        return;
    }

    isPosting.value = true;

    const data = await send();

    if (props.refershLookups) {
        store.refershLookups();
    }

    isPosting.value = false;
    emits('update:modelValue', {
        old: props.item,
        new: data
    });
};

const send = async () => {
    const url = props.url;

    if (props.item.id) {
        const { data } = await props.fun(`${url}/edit`, props.item);
        return data;
    } else {
        const { data } = await props.fun(`${url}/add`, props.item);
        return data;
    }
}

onMounted(() => {
    document.addEventListener('keydown', close);
    document.addEventListener('keydown', save);
});

onUnmounted(() => {
    document.removeEventListener('keydown', close);
    document.removeEventListener('keydown', save);
});

const close = (e) => {
    if (isPosting.value) {
        return;
    }

    if (e.key === 'Escape') {
        emits('close');
    }
}

const save = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
        submit();
    }
}

</script>

<template>

    <div class="flex flex-col justify-center sm:items-center">
        <Card class="w-full p-4">

            <CardHeader>
                <CardTitle>
                    <div class="flex flex-row items-center justify-between">
                        <span class="flex flex-row items-center gap-2">
                            <Icon :icon="props.icon" class="w-4 h-4" />
                            <div class="font-medium">{{ props.title ? props.title : props.item.id ? t('common.edit') : t('common.create') }}</div>
                        </span>
                        <div class="flex flex-row items-center gap-2">
                            <slot name="head-right"></slot>
                            <ConfirmClose :isDisabled="isPosting" @close="emits('close')" />
                        </div>
                    </div>
                </CardTitle>
            </CardHeader>

            <CardContent class="w-full space-y-3">
                <slot name="item"></slot>
            </CardContent>

            <CardFooter class="flex flex-row px-6 pb-6 gap-2 justify-end">
                <slot name="controls">
                    <ProcessingButton @click="submit" :disabled="isPosting || !props.isValid" :processing="isPosting" :text="props.item.id ? t('common.edit') : t('common.save')" />
                </slot>
            </CardFooter>
        </Card>
    </div>

</template>