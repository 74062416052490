<script setup>
import { onMounted, reactive } from "vue"
import { useI18n } from 'vue-i18n'

import { useForm } from 'vee-validate'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import { post } from "@/utils/http";
import { useRouter, useRoute } from 'vue-router';
import { isUserAuthenticated } from '@/utils/auth';

import { cn } from '@/lib/utils'

import { icons } from "@/utils/icons"

import { navigateHome } from "@/utils/nav";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const state = reactive({
    isLoading: false,
    message: ''
});

const formSchema = toTypedSchema(z.object({
    name: z.string().min(1),
    businessId: z.string().min(1),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    confirmEmail: z.string().email().refine((data) => data.confirmEmail === data.email, {
        message: t('common.emailsMustMatch'),
    }),
}))

const { handleSubmit, setValues, meta } = useForm({
    validationSchema: formSchema,
})

const onSubmit = handleSubmit(async (values, { resetForm }) => {
    state.isLoading = true;

    post('auth/register', {
        ...values,
        returnUrl: route.query.ReturnUrl
    }).then((_) => {
        state.message = t('views.auth.login.magicLink');
    }).catch((error) => {
        console.error(error);
    }).finally(() => {
        state.isLoading = false;
    })

    setValues({});
    resetForm();
})

onMounted(() => {
    if (isUserAuthenticated()) {  

        navigateHome(router);
        return;
    }
});

</script>

<template>
    <ScrollArea class="h-full w-full">
        <div class="flex flex-row items-center justify-center h-svh">
            <Card class="flex flex-col justify-center space-y-6 sm:w-[350px] p-4">
                <div class="flex flex-col space-y-2 text-center items-center">
                    <figure class="mb-3">
                        <AlinorLogo />
                    </figure>
                    {{ t('views.auth.register.register') }}
                    <Transition mode="out-in">
                        <p v-if="!state.message" class="text-sm text-muted-foreground">
                            {{ t('views.auth.register.yourAccountWillbeValidatedByAlinor') }}
                        </p>
                    </Transition>
                </div>
                <Transition mode="out-in">
                    <Alert class="w-full text-start" v-if="state.message">
                        <Icon :icon="icons.rocket" class="h-4 w-4" />
                        <AlertTitle>{{ t('views.auth.login.checkYourInbox') }}</AlertTitle>
                        <AlertDescription>
                            {{ state.message }}
                        </AlertDescription>
                    </Alert>
                    <div v-else :class="cn('grid gap-6', $attrs.class ?? '')">
                        <div>
                            <div class="grid gap-2">
                                <div class="w-full space-y-6">
                                    <Transition mode="out-in">
                                        <div v-if="!state.isLoading" class="grid gap-1">
                                            <div class="flex flex-row justify-between gap-3">
                                                <FormField v-slot="{ componentField }" name="name">
                                                    <FormItem v-auto-animate>
                                                        <FormLabel>{{ t('views.auth.register.organization') }}</FormLabel>
                                                        <FormControl>
                                                            <Input type="text" v-bind="componentField" />
                                                        </FormControl>
                                                        <FormDescription>
                                                        </FormDescription>
                                                        <FormMessage />
                                                    </FormItem>
                                                </FormField>

                                                <FormField v-slot="{ componentField }" name="businessId">
                                                    <FormItem v-auto-animate>
                                                        <FormLabel>{{ t('views.auth.register.businessId') }}</FormLabel>
                                                        <FormControl>
                                                            <Input type="text" v-bind="componentField" />
                                                        </FormControl>
                                                        <FormDescription>
                                                        </FormDescription>
                                                        <FormMessage />
                                                    </FormItem>
                                                </FormField>

                                            </div>

                                            <div class="flex flex-row justify-between gap-3">
                                                <FormField v-slot="{ componentField }" name="firstName">
                                                    <FormItem v-auto-animate>
                                                        <FormLabel>{{ t('common.firstName') }}</FormLabel>
                                                        <FormControl>
                                                            <Input type="text" v-bind="componentField" />
                                                        </FormControl>
                                                        <FormDescription>
                                                        </FormDescription>
                                                        <FormMessage />
                                                    </FormItem>
                                                </FormField>

                                                <FormField v-slot="{ componentField }" name="lastName">
                                                    <FormItem v-auto-animate>
                                                        <FormLabel>{{ t('common.lastName') }}</FormLabel>
                                                        <FormControl>
                                                            <Input type="text" v-bind="componentField" />
                                                        </FormControl>
                                                        <FormDescription>
                                                        </FormDescription>
                                                        <FormMessage />
                                                    </FormItem>
                                                </FormField>

                                            </div>


                                            <FormField v-slot="{ componentField }" name="email">
                                                <FormItem v-auto-animate>
                                                    <FormLabel>{{ t('common.email') }}</FormLabel>
                                                    <FormControl>
                                                        <Input type="email" v-bind="componentField" />
                                                    </FormControl>
                                                    <FormDescription>
                                                    </FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            </FormField>

                                            <FormField v-slot="{ componentField }" name="confirmEmail">
                                                <FormItem v-auto-animate>
                                                    <FormLabel>{{ t('common.confirmEmail') }}</FormLabel>
                                                    <FormControl>
                                                        <Input type="email" v-bind="componentField" />
                                                    </FormControl>
                                                    <FormDescription>
                                                    </FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            </FormField>
                                        </div>
                                    </Transition>

                                    <Button @click="onSubmit" variant="default" :disabled="state.isLoading || !meta.valid" class="w-full">
                                        <Transition mode="out-in">
                                            <span v-if="!state.isLoading">
                                                {{ t('views.auth.register.register') }}
                                            </span>
                                            <Icon :icon="icons.loaderCircle" v-else class="h-4 w-4 animate-spin" />
                                        </Transition>
                                    </Button>
                                </div>
                                <span class="text-muted-foreground text-center">{{ t('common.or') }} <router-link :to="{ name: 'login' }">{{ t('views.auth.login.signIn') }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </Transition>
            </Card>
        </div>
    </ScrollArea>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
