<script setup>

import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { icons } from '@/utils/icons';
import { useGlobalState } from '@/views/_shared/stores/GlobalState';
import { useRouter } from 'vue-router';

import { post } from '@/utils/http';

import { getCommentStatusColor } from '@/lib/utils';

const { t } = useI18n();
const store = useGlobalState();
const router = useRouter();

const props = defineProps({
    comment: {
        type: Object,
        required: true,
    },
    canEdit: {
        type: Boolean,
        default: false,
    },
});

const getDeviationColor = () => {
    if (props.comment.isDeviation) {
        return 'bg-red-100 text-red-800';
    }
    return '';
}

const goPro = () => {
    router.push({ name: 'buyer-manage-project', params: { id: props.comment.project.id } });
}

const goPack = () => {
    router.push({ name: 'buyer-manage-package', params: { id: props.comment.package.id } });
}

const isResolveModalOpen = ref(false);
const isResolving = ref(false);

const resolveItem = ref({
    id: props.comment.id,
    text: '',
});

watch(() => isResolveModalOpen, (v) => {
    if (!v) {
        resolveItem.value.text = '';
    }

    isResolving.value = false;
});

const resolve = () => {
    if (!props.canEdit) {
        return;
    }

    if (isResolving.value) {
        return;
    }

    if (!resolveItem.value.text) {
        return;
    }

    isResolving.value = true;

    post(`/procurement/work-items/resolve`, {
        id: resolveItem.value.id,
        text: resolveItem.value.text
    }).then(({ data }) => {

        props.comment.status = data.status;

        isResolveModalOpen.value = false;
    }).finally(() => {
        isResolving.value = false;
    });
}

const isAssignModalOpen = ref(false);
const isAssigning = ref(false);

const assignItem = ref({
    id: props.comment.id,
    userId: null,
    targetDate: null,
});

watch(() => isAssignModalOpen, (v) => {
    if (!v) {
        assignItem.value.userId = null;
        assignItem.value.targetDate = null;
    }

    isAssigning.value = false;
});

const assign = () => {
    if (!props.canEdit) {
        return;
    }

    if (isAssigning.value) {
        return;
    }

    if (!assignItem.value.userId || !assignItem.value.targetDate) {
        return;
    }

    isAssigning.value = true;

    post(`/procurement/work-items/assign`, {
        id: assignItem.value.id,
        userId: assignItem.value.userId,
        targetDate: assignItem.value.targetDate
    }).then(({ data }) => {

        props.comment.assignedTo = data.assignedTo;
        props.comment.targetDate = data.targetDate;

        isAssignModalOpen.value = false;
    }).finally(() => {
        isAssigning.value = false;
    });
}
</script>

<template>

    <Card class="flex flex-row items-center justify-between rounded-md border p-4 w-full">
        <div class="flex flex-col gap-2" :class="getDeviationColor()">
            <div class="flex flex-row items-center gap-x-3">
                <Transition mode="out-in">
                    <div v-if="comment.isDeviation">
                        <Tooltip>
                            <TooltipTrigger>
                                <Icon :icon="icons.deviation" />
                            </TooltipTrigger>
                            <TooltipContent>
                                {{ t('common.deviation') }}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                </Transition>
                <Transition mode="out-in">
                    <div v-if="comment.assignedTo">
                        <Tooltip>
                            <TooltipTrigger>
                                <Avatar>
                                    <AvatarFallback>{{ comment.assignedTo.initials }}</AvatarFallback>
                                </Avatar>
                            </TooltipTrigger>
                            <TooltipContent>
                                {{ comment.assignedTo.name }}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                </Transition>
                <div class="flex flex-col gap-1">
                    <Transition mode="out-in">
                        <span v-if="comment.title">{{ comment.title }}</span>
                    </Transition>
                    <span>
                        {{ comment.text }}
                    </span>
                </div>
            </div>
            <Transition mode="out-in">
                <div v-if="comment.project || comment.package || comment.vendor" class="flex flex-row gap-1">
                    <Tooltip>
                        <TooltipTrigger>
                            <Badge v-if="comment.project" class="flex flex-row gap-1" @click="goPro">
                                <Icon :icon="icons.project" />
                                <span>{{ comment.project.name }}</span>
                            </Badge>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.project') }}
                        </TooltipContent>
                    </Tooltip>
                    <Tooltip>
                        <TooltipTrigger>
                            <Badge v-if="comment.package" class="flex flex-row gap-1" variant="outline" @click="goPack">
                                <Icon :icon="icons.package" />
                                <span>{{ comment.package.name }}</span>
                            </Badge>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.package') }}
                        </TooltipContent>
                    </Tooltip>
                    <Tooltip>
                        <TooltipTrigger>
                            <Badge v-if="comment.vendor" class="flex flex-row gap-1" variant="secondary">
                                <Icon :icon="icons.vendors" />
                                <span>{{ comment.vendor.name }}</span>
                            </Badge>
                        </TooltipTrigger>
                        <TooltipContent>
                            {{ t('common.vendor') }}
                        </TooltipContent>
                    </Tooltip>
                </div>
            </Transition>
        </div>
        <div class="flex flex-row items-center gap-x-3">
            <Transition mode="out-in">
                <TimeAgo v-if="comment.targetDate" :date="comment.targetDate"  :label="t('common.targetDate')" />
            </Transition>
            <Badge variant="outline" class="w-fit" :class="getCommentStatusColor(comment.status)">
                {{ store.ev(store.enums.CommentStatus, comment.status) }}
            </Badge>
            <DropdownMenu v-if="props.canEdit">
                <DropdownMenuTrigger as-child>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                        <Icon :icon="icons.more" class="h-4 w-4" />
                        <span class="sr-only">Toggle menu</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuLabel>{{ t('common.actions') }}</DropdownMenuLabel>
                    <DropdownMenuItem @click="isResolveModalOpen = true" class="flex flex-row justify-between items-center hover:cursor-pointer">
                        <span>{{ t('common.resolve') }}</span>
                        <Icon :icon="icons.check" class="h-4 w-4" />
                    </DropdownMenuItem>
                    <DropdownMenuItem @click="isAssignModalOpen = true" class="flex flex-row justify-between items-center hover:cursor-pointer">
                        <span>{{ t('common.assignTo') }}</span>
                        <Icon :icon="icons.user" class="h-4 w-4" />
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    </Card>

    <Modal :open="isResolveModalOpen" @update:open="v => isResolveModalOpen = v">
        <template #controls>
            <div class="w-full flex flex-col grow justify-stretch items-stretch h-full">
                <Textarea v-model="resolveItem.text" class="w-full h-full mb-2" :placeholder="t('common.reason')"></Textarea>
            </div>
        </template>
        <template #footer>
            <div class="flex flex-row w-full justify-between px-4">
                <Button @click="isResolveModalOpen = false" variant="outline">{{ t('common.cancel') }}</Button>
                <ProcessingButton :disabled="!resolveItem.text" @click="resolve" :processing="isResolving" variant="default" :text="t('common.resolve')" />
            </div>
        </template>
    </Modal>

    <Modal :open="isAssignModalOpen" @update:open="v => isAssignModalOpen = v">
        <template #controls>
            <div class="w-full flex flex-col gap-2 grow justify-stretch items-stretch h-full">
                <Dropdown :items="store.types.users" :title="t('common.assignToUser')" :icon="icons.user" :modelValue="assignItem.userId" @update:modelValue="v => assignItem.userId = v" />
                <DatePicker :modelValue="assignItem.targetDate" :title="t('common.targetDate')" @update:modelValue="v => assignItem.targetDate = v" />
            </div>
        </template>
        <template #footer>
            <div class="flex flex-row w-full justify-between px-4">
                <Button @click="isAssignModalOpen = false" variant="outline">{{ t('common.cancel') }}</Button>
                <ProcessingButton :disabled="!assignItem.userId || !assignItem.targetDate" @click="assign" :processing="isAssigning" variant="default" :text="t('common.save')" />
            </div>
        </template>
    </Modal>

</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>